import React from "react";
import { useTranslation } from "react-i18next";
import "../../languages/i18nextConf";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LogoBottom from "../../images/home/logo-footer.png";
import pm_footer from "../../images/home/pm-footer.png";
import phonePerson from "../../images/home/phone_person.png";
import st from "./styles.module.scss";

import Logic from "./Logic";

const Index = () => {
    const { t } = useTranslation();

    const { togglePopUp } = Logic();
    return (
        <>
            <footer className={st.footer}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className={st.border}>
                                <img className={st.logo} src={LogoBottom} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className={st.contacts_info}>
                                <ul>
                                    <li>{t("footer.hours_1")} </li>
                                    <li>04:00 - 01:00</li>
                                    <li>{t("footer.hours_2")} </li>
                                </ul>
                                <ul>
                                    <li>{t("footer.address_1")} </li>
                                    <li>{t("footer.address_2")} </li>
                                    <li>{t("footer.address_3")} </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className={st.phone_wrapper}>
                                <div className={st.phone}>
                                    <img src={phonePerson} alt='' />
                                    <a href='tel:+41225101440'>+41 22 510 14 40</a>
                                </div>
                                <div className={st.email}>
                                    <img src={phonePerson} alt='' />
                                    <a href='mailto:info@parkflygeneva.ch'>info@parkflygeneva.ch</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={st.socket}>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <p className={st.info}>{t("footer.copyrignht")}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p className={st.tc} onClick={() => togglePopUp("showTerms", true)}>
                                    {t("footer.tc_link")}
                                </p>
                            </div>
                            <div className='col-lg-4'>
                                <div className={st.payments}>
                                    <img src={pm_footer} alt='' />
                                    <p>{t("footer.payment_methods")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Index;
