import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { login } from "./_actions";

const LoginLogic = () => {
    const dispatch = useDispatch();

    const [loading, setLoader] = useState(false);

    const [passType, setPassType] = useState(false);
    const togglePassType = () => setPassType(!passType);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        login(data, dispatch).then((response = {}) => {
            setLoader(false);

            if (response.errors) {
                setErrors(response.errors);
            }
        });
    };

    return { onSubmit, loading, passType, togglePassType };
};

export default LoginLogic;
