import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSingleParking } from "./_actions";

const PreviewLogic = () => {
    const dispatch = useDispatch();

    const parkingId = useSelector((state) => state.singleParking.parkingId);
    const showPreview = useSelector((state) => state.singleParking.showPreview);

    useEffect(() => {
        if (parkingId && showPreview) {
            getSingleParking(parkingId, dispatch);
        }
    }, [parkingId, showPreview]);

    const openParkingPreview = (data) => {
        dispatch({
            type: "SHOW_PREVIEW_PARKING_POPUP",
            payload: { id: "", status: data },
        });
    };

    const openParkingEdit = (id) => {
        dispatch({
            type: "SHOW_EDIT_PARKING_POPUP",
            payload: { id, status: true },
        });
        dispatch({
            type: "SHOW_PREVIEW_PARKING_POPUP",
            payload: { id, status: false },
        });
    };

    return { openParkingPreview, openParkingEdit };
};

export default PreviewLogic;
