import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import st from "./styles.module.scss";

const Preview = () => {
    const { t } = useTranslation();

    const client = useSelector((state) => state.singleClient.client);
    const vehicles = useSelector((state) => state.singleClient.vehicles);
    const {
        id,
        first_name,
        last_name,
        company,
        email,
        phone,
        country,
        city,
        district,
        post_code,
        street,
        street_number,
    } = client;
    const { name: countryName } = country || {};
    return (
        <div className='container-fluid wrapper'>
            <div className='row'>
                <div className='col-md-8'>
                    <div className={st.client_summary + " bg"}>
                        <div className={st.summary}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h3 className={st.sub_title}>
                                        {t("clients.preview.first_title")}
                                    </h3>
                                    <table className={st.personal_table}>
                                        <tbody>
                                            <tr>
                                                <td> {t("clients.preview.first_name")}</td>
                                                <td>{first_name ? first_name : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td> {t("clients.preview.last_name")}</td>
                                                <td>{last_name ? last_name : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td> {t("clients.preview.company")}</td>
                                                <td>{company ? company : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td> {t("clients.preview.email")}</td>
                                                <td>{email ? email : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td> {t("clients.preview.phone")}</td>
                                                <td>{phone ? phone : "-"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='col-md-6'>
                                    <h3 className={st.sub_title}>
                                        {t("clients.preview.second_title")}
                                    </h3>
                                    <table className={st.personal_table}>
                                        <tbody>
                                            <tr>
                                                <td>{t("clients.preview.country")}</td>
                                                <td>{countryName ? countryName : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("clients.preview.city")}</td>
                                                <td>{city ? city : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("clients.preview.district")}</td>
                                                <td>{district ? district : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td> {t("clients.preview.post_code")}</td>
                                                <td>{post_code ? post_code : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td> {t("clients.preview.street")}</td>
                                                <td>{street ? street : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td> {t("clients.preview.street_number")}</td>
                                                <td>{street_number ? street_number : "-"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className={st.client_summary + " bg"}>
                        <h3 className={st.sub_title}>Vehicles</h3>
                        <div className={st.vehicles_table_wrap}>
                            <table className={st.vehicles_table}>
                                <tr>
                                    <th> {t("clients.preview.brand")}</th>
                                    <th> {t("clients.preview.model")}</th>
                                    <th> {t("clients.preview.plate")}</th>
                                </tr>
                                {vehicles.length > 0 &&
                                    vehicles.map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.brand}</td>
                                                <td>{item.model}</td>
                                                <td>{item.plate}</td>
                                            </tr>
                                        );
                                    })}
                                {vehicles.length === 0 && (
                                    <tr>
                                        <td colSpan='3' className='text-center'>
                                            {t("clients.preview.noResults")}
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preview;
