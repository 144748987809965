import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateSingleClient, getCountriesList } from "./_actions";
import { useHistory } from "react-router";

const EditLogic = () => {
    const dispatch = useDispatch();
    const client = useSelector((state) => state.singleClient.client);
    const countriesList = useSelector((state) => state.clientBooking.countriesList);
    const history = useHistory();

    const initialState = {
        id: "",
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        phone: "",
        country_id: "",
        city: "",
        post_code: "",
        street: "",
        street_number: "",
        discount: "",
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        getCountriesList(dispatch);
    }, []);

    const [country, setCountry] = useState({
        id: "",
        name: "",
    });

    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            setInital();
        }
    }, [client]);

    const setInital = () => {
        const { country, ...rest } = client;
        setState({ name: rest.name + "*", ...rest });

        setTimeout(() => {
            setState(rest);
            setCountry({
                id: country.id,
                name: country.name,
            });
        }, 100);
    };
    const handleCountryChange = (event, value, reason, name) => {
        if (value) {
            const selected = countriesList.filter((item) => item.id == value.id)[0];
            setCountry({
                id: value.id,
                name: selected.name,
            });
        } else {
            setCountry({
                id: "",
                name: "",
            });
        }
    };
    const urlBase = "/dashboard/clients/";
    const goBack = () => {
        history.push(urlBase);
    };
    const [loading, setLoader] = useState(false);
    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        const moderated = { ...data, country_id: country.id };

        updateSingleClient(moderated, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 204) {
                history.push(urlBase);
            }

            if (response.errors) {
                let moderatedErrors = { error: "" };
                for (const property in response.errors) {
                    moderatedErrors = {
                        ...moderatedErrors,
                        [property]: response.errors[property].toString(),
                    };
                }

                setErrors(response.errors);
            }
        });
    };

    return {
        state,
        loading,
        onSubmit,
        handleCountryChange,
        countriesList,
        country,
        setInital,
        goBack,
    };
};

export default EditLogic;
