import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { getStatistics } from "./_actions";

import moment from "moment";

const StatisticsLogic = () => {
    const { t } = useTranslation();
    const monthNames = [
        t("statistics.january"),
        t("statistics.february"),
        t("statistics.march"),
        t("statistics.april"),
        t("statistics.may"),
        t("statistics.june"),
        t("statistics.july"),
        t("statistics.august"),
        t("statistics.september"),
        t("statistics.october"),
        t("statistics.november"),
        t("statistics.december"),
    ];

    const dispatch = useDispatch();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");

    useEffect(() => {
        const year = moment().year();
        const month = moment().month();
        setYear(year);
        setMonth(month);
    }, []);
    useEffect(() => {
        if (month !== "" && year !== "") {
            getStatistics(month + 1, year, dispatch);
        }
    }, [month, year]);

    const changeMonth = (type) => {
        if (type === "increase") {
            if (month === 11) {
                setMonth(0);
                setYear(year + 1);
            } else {
                setMonth(month + 1);
            }
        } else if (type === "decrease") {
            if (month === 0) {
                setMonth(11);
                setYear(year - 1);
            } else {
                setMonth(month - 1);
            }
        }
    };
    return { year, month, monthNames, changeMonth };
};

export default StatisticsLogic;
