import axios from "../../../../configAxios.js";
import { setMessage } from "../../../../components/AlertMessage/_actions.js";

export const getClientsList = (querySting = "", dispatch) => {
    return axios
        .get("/clients?" + querySting)
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_CLIENTS_LIST",
                    payload: data.data,
                });
                dispatch({
                    type: "SET_TABLE_META",
                    payload: data.meta,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const resendInvitation = (id, dispatch) => {
    return axios
        .put("/employees/resend-invitation/" + id)
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const deleteSingleClient = (id, dispatch) => {
    return axios
        .delete("/employees/" + id)
        .then((result) => {
            const { status, data } = result;
            if (status === 204) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
