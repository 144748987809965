import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import i18n from "../../languages/i18nextConf";
import { useSelector, useDispatch } from "react-redux";

const Logic = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuth = useSelector((state) => state.clientAuth.token_client);
    const lang = useSelector((state) => state.clientProfile.profile.lang) || "fr";

    const [isReservacion, setIsReservation] = useState(false);

    useEffect(() => {
        if (history.location.pathname === "/reservation") {
            setIsReservation(true);
        } else {
            setIsReservation(false);
        }
    }, [history]);

    useEffect(() => {
        setLanguage(lang);
        i18n.changeLanguage(lang);
    }, [lang]);
    const [language, setLanguage] = useState(lang);
    const changeLanguage = (val) => {
        setLanguage(val);
        i18n.changeLanguage(val);
    };

    useEffect(() => {
        if (history.location.pathname === "/reset-password") {
            togglePopUp("showResetPassword", true);
        }
    }, []);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    const togglePopUpProfile = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };
    const resetBookingForm = (name, value) => {
        dispatch({
            type: "RESET_BOOKING_DATA",
            payload: "",
        });
    };

    return {
        isAuth,
        togglePopUp,
        togglePopUpProfile,
        language,
        changeLanguage,
        resetBookingForm,
        isReservacion,
    };
};

export default Logic;
