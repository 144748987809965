import axios from "../../../../configAxios.js";
import { setMessage } from "../../../../components/AlertMessage/_actions.js";

import moment from "moment-timezone";

export const getEmployeesBirthdays = (month, dispatch) => {
    dispatch({
        type: "GET_EMPLOYEES_BIRTHDAYS_CALENDAR",
        payload: [
            {
                date: "2021-08-13 00:00:00",
                id: 5,
                name: "Jhon Someone",
            },
            {
                date: "2021-09-01 00:00:00",
                id: 5,
                name: "Jhon Someone",
            },
        ],
    });
};

export const getVacationsCalendar = (month, dispatch) => {
    const startDate = month;
    const year = moment(month, "DD-MM-YYYY").format("YYYY");
    const exactMonth = moment(month, "DD-MM-YYYY").format("MM");
    const lastDayOfMonth = new Date(year, exactMonth, 0).getDate();
    const endDate = lastDayOfMonth + "-" + exactMonth + "-" + year;

    dispatch({
        type: "SHOW_CALENDAR_LOADER",
        payload: true,
    });

    return axios
        .get("/vacation?filter[from_date]=" + startDate + "&filter[to_date]=" + endDate)
        .then((result) => {
            const { status, data } = result;

            if (status === 200) {
                dispatch({
                    type: "GET_VACATIONS_CALENDAR",
                    payload: data.data,
                });

                dispatch({
                    type: "SHOW_CALENDAR_LOADER",
                    payload: false,
                });
            }
            return result;
        })
        .catch((error) => {
            dispatch({
                type: "SHOW_CALENDAR_LOADER",
                payload: false,
            });
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
