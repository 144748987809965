import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";

import Logic from "./Logic";
import step_1 from "../../../../images/home_icons/step_1.svg";
import step_2 from "../../../../images/home_icons/step_2.svg";
import step_3 from "../../../../images/home_icons/step_3.svg";
import step_4 from "../../../../images/home_icons/step_4.svg";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();
    const texts = [
        { num: "01", title: t("how_does_work.box_title_1"), text: t("how_does_work.box_text_1") },
        { num: "02", title: t("how_does_work.box_title_2"), text: t("how_does_work.box_text_2") },
        { num: "03", title: t("how_does_work.box_title_3"), text: t("how_does_work.box_text_3") },
        { num: "04", title: t("how_does_work.box_title_4"), text: t("how_does_work.box_text_4") },
    ];
    const { sliderSettings, resetBookingForm } = Logic();

    return (
        <>
            <div className={st.how_does_work}>
                <h3 className={st.title}>
                    <b> {t("how_does_work.title_1")}</b> {t("how_does_work.title_2")}
                </h3>
                <div className={st.hdw_d}>
                    <div className={st.inner_wrapper}>
                        <div className={st.col}>
                            <div className={st.box + " " + st.box_1}>
                                <div className={st.title_wrapper}>
                                    <div>
                                        <span>{texts[0].num}</span>
                                        <h4 className={st.box_title}>{texts[0].title}</h4>
                                    </div>
                                    <div className={st.circle}>
                                        <img src={step_1} alt='' />
                                    </div>
                                </div>
                                <p className={st.text}> {texts[0].text}</p>
                            </div>
                            <div className={st.box + " " + st.box_3}>
                                <div className={st.title_wrapper}>
                                    <div>
                                        <span>{texts[2].num}</span>
                                        <h4 className={st.box_title}>{texts[2].title}</h4>
                                    </div>
                                    <div className={st.circle}>
                                        <img src={step_3} alt='' />
                                    </div>
                                </div>
                                <p className={st.text}> {texts[2].text}</p>
                            </div>
                        </div>
                        <div className={st.col + " " + st.spacer}>
                            <div className={st.box + " " + st.box_2}>
                                <div className={st.title_wrapper}>
                                    <div>
                                        <span>{texts[1].num}</span>
                                        <h4 className={st.box_title}>{texts[1].title}</h4>
                                    </div>
                                    <div className={st.circle}>
                                        <img src={step_2} alt='' />
                                    </div>
                                </div>
                                <p className={st.text}> {texts[1].text}</p>
                            </div>

                            <div className={st.box + " " + st.box_4}>
                                <div className={st.title_wrapper}>
                                    <div>
                                        <span>{texts[3].num}</span>
                                        <h4 className={st.box_title}>{texts[3].title}</h4>
                                    </div>
                                    <div className={st.circle}>
                                        <img src={step_4} alt='' />
                                    </div>
                                </div>
                                <p className={st.text}> {texts[3].text}</p>
                            </div>
                        </div>
                        <div className={st.separator}>{/* <img src={w_separator} alt='' /> */}</div>
                    </div>
                    <div className={st.btn_wrapper}>
                        <Link
                            className='green_btn_h'
                            to='reservation'
                            onClick={() => {
                                resetBookingForm();
                            }}
                        >
                            {t("book_btn")}
                        </Link>
                    </div>
                </div>
                <div className={st.hdw_m}>
                    <Slider {...sliderSettings} className={st.slider_wrapper}>
                        {texts.map((item, i) => {
                            return (
                                <div className={st.box} key={item.num}>
                                    <div className={st.title_wrapper}>
                                        <div>
                                            <span>{item.num}</span>
                                            <h4 className={st.box_title}>{item.title}</h4>
                                        </div>
                                        <div className={st.circle}>
                                            <img src={"step_" + i} alt='' />
                                        </div>
                                    </div>
                                    <p className={st.text}> {item.text}</p>
                                </div>
                            );
                        })}
                    </Slider>
                    <div className={st.button_wrapper}>
                        <Link
                            className='green_btn_h'
                            to='reservation'
                            onClick={() => {
                                resetBookingForm();
                            }}
                        >
                            {t("book_btn")}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
