import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import phonePerson from "../../../../images/home/phone_person.png";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const { faq, toggleFaq } = Logic();

    return (
        <>
            <div className={st.faq_section}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3>
                                <b>{t("faq_section.title_1_1")} </b> {t("faq_section.title_1_2")}
                            </h3>
                            <div className={st.faq_wrapper}>
                                {faq.map((item, i) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className={
                                                st.element + " " + (item.open ? st.open : "")
                                            }
                                        >
                                            <h4 onClick={() => toggleFaq(item)}>
                                                {t("faq_section.title_" + (i + 1))}
                                                <div className={st.carret}>
                                                    {item.open ? "-" : "+"}
                                                </div>
                                            </h4>
                                            <div> {t("faq_section.text_" + (i + 1))}</div>
                                        </div>
                                    );
                                })}

                                <div className={st.callUs_banner}>
                                    <p>
                                        <b>{t("faq_section.callUs_banner_1")}</b>{" "}
                                        {t("faq_section.callUs_banner_2")}
                                        <br /> {t("faq_section.callUs_banner_3")}
                                    </p>
                                    <div className={st.phone}>
                                        <img src={phonePerson} alt='' />
                                        <a href='tel:+41225101440'>+41 22 510 14 40</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
