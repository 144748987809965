import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../../languages/i18nextConf";

import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import loginSchema from "../../../../../_validations/loginSchema";

import st from "./styles.module.scss";
import LoginClientLogic from "./Login";

const LoginClient = () => {
    const { t } = useTranslation();
    const { onSubmit, passType, setPassType, loading } = LoginClientLogic();
    return (
        <Formik
            enableReinitialize
            initialValues={{
                email: "",
                password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
        >
            {({ dirty, isValid }) => {
                return (
                    <Form className='client_forms'>
                        <label className='label_custom' htmlFor=''>
                            {t("client_booking.step_2.email") + "*"}
                        </label>
                        <Field
                            name='email'
                            type='text'
                            className='input_label'
                            component={TextField}
                        ></Field>
                        <label htmlFor=''>{t("client_booking.step_2.password") + "*"}</label>
                        <div className='password_input'>
                            <Field
                                name='password'
                                type={passType ? "text" : "password"}
                                className='input_label'
                                component={TextField}
                            ></Field>
                            <i
                                className='password_icon fas fa-eye'
                                onClick={() => setPassType(!passType)}
                            ></i>
                        </div>
                        <div className={st.btn_wrapper}>
                            <button
                                disabled={loading || !(isValid && dirty)}
                                type='submit'
                                className='green_btn_h'
                            >
                                {t("client_booking.step_2.login_btn")}
                            </button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default LoginClient;
