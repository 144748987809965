import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { TextField } from "formik-material-ui";
import { useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";

import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";

import clientProfileShema from "../../../../_validations/clientProfileShema";

import st from "../styles.module.scss";
import EditProfileLogic from "./EditProfileLogic";

const EditProfile = () => {
    const { t } = useTranslation();
    const countriesList = useSelector((state) => state.clientBooking.countriesList) || [];
    const { phoneCode, country, toggleScreen, state, loading, onSubmit, handleCountryChange } =
        EditProfileLogic();
    const { country_id } = state;

    return (
        <div className={st.form_wrapper}>
            <h3>{t("client_profile.edit_personal_title")}</h3>
            <Formik
                enableReinitialize
                initialValues={state}
                validationSchema={clientProfileShema}
                onSubmit={onSubmit}
            >
                {({ errors, touched, isValid, setFieldValue, setTouched }) => {
                    return (
                        <Form className='client_forms '>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <Field
                                        name='first_name'
                                        type='text'
                                        className='input_label'
                                        component={TextField}
                                        label={t("client_profile.first_name")}
                                    ></Field>
                                </div>
                                <div className='col-md-4'>
                                    <Field
                                        name='last_name'
                                        type='text'
                                        className='input_label'
                                        label={t("client_profile.last_name")}
                                        component={TextField}
                                    ></Field>
                                </div>
                                <div className='col-md-4'>
                                    <Field
                                        name='company'
                                        type='text'
                                        className='input_label'
                                        component={TextField}
                                        label={t("client_profile.company")}
                                    ></Field>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Field
                                        name='country'
                                        value={country}
                                        className='autocomplete_label mt-3'
                                        options={countriesList}
                                        getOptionLabel={(option) => option.name}
                                        component={Autocomplete}
                                        label={t("client_profile.country")}
                                        onChange={
                                            ((e) => setFieldValue("country_id", e.target.value),
                                            handleCountryChange)
                                        }
                                        onBlur={() => setTouched({ ["country_id"]: true })}
                                        renderInput={(params) => {
                                            return (
                                                <MuiTextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "off",
                                                    }}
                                                    name='country_id'
                                                    error={
                                                        touched["country_id"] &&
                                                        errors["country_id"]
                                                    }
                                                    helperText={
                                                        touched["country_id"] &&
                                                        errors["country_id"]
                                                    }
                                                />
                                            );
                                        }}
                                    ></Field>
                                </div>

                                <div className='col-md-4'>
                                    <Field
                                        name='city'
                                        type='text'
                                        className='input_label'
                                        component={TextField}
                                        label={t("client_profile.city")}
                                    ></Field>
                                </div>
                                <div className='col-md-2'>
                                    <Field
                                        name='post_code'
                                        type='text'
                                        className='input_label'
                                        component={TextField}
                                        label={t("client_profile.post_code")}
                                    ></Field>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='phone_code_wrapper with_label'>
                                        <Field
                                            name='phoneCode'
                                            type='text'
                                            disabled
                                            value={phoneCode}
                                            className='input_label phone_code'
                                            component={TextField}
                                        ></Field>
                                        <Field
                                            name='phone'
                                            type='text'
                                            className='input_label phone'
                                            component={TextField}
                                            label={t("client_profile.phone")}
                                        ></Field>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <Field
                                        name='street'
                                        type='text'
                                        className='input_label'
                                        component={TextField}
                                        label={t("client_profile.address")}
                                    ></Field>
                                </div>
                                <div className='col-md-2'>
                                    <Field
                                        name='street_number'
                                        type='text'
                                        className='input_label'
                                        component={TextField}
                                        label={t("client_profile.street_number")}
                                    ></Field>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <button type='submit' className='green_btn_h' disable={loading}>
                                        {t("client_profile.update")}
                                    </button>
                                    <button
                                        type='button'
                                        className='grey_btn_h'
                                        onClick={() => toggleScreen(1)}
                                    >
                                        {t("client_profile.cancel")}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default EditProfile;
