import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";
import { useSelector, useDispatch } from "react-redux";

import ClosePopup from "../../../images/icons/close-popup-icon.svg";
import SuccessIcon from "../../../images/home/success_icon.png";
import st from "./styles.module.scss";

const EmailSuccess = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const show = useSelector((state) => state.clientAuth.showSuccessEmail);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    return (
        <div className={st.info_popup + " pop client_forms " + (show ? "show" : "")}>
            <div className={st.inner + " inner"}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => togglePopUp("showSuccessEmail", false)}
                />
                <div className={st.form_wrapper + " text-center"}>
                    <img src={SuccessIcon} alt='' className='mb-3' />
                    <h3 className='mb-2'>Success!</h3>
                    <p className={st.introText + " mb-4"}>
                        Your password has been updated successfully.
                    </p>
                    <div className='text-center'>
                        <button
                            type='button'
                            onClick={() => {
                                togglePopUp("showSuccessEmail", false);
                            }}
                            className='green_btn_h'
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailSuccess;
