import axios from "../../../configAxios.js";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

export const getPricesList = (dispatch) => {
    return axios
        .get("/price")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_PRICES_LIST",
                    payload: data.data.price_list,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const setPrices = (data, dispatch) => {
    return axios
        .post("/price", data)
        .then((result) => {
            const { status, data } = result;
            if (status === 204) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.response.data.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
