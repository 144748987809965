// homePageReducer

const initialState = {
    reviews: [],
    showPriceCalculator: false,
    parkingLots: 50,
};

const clientAuth = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_PARKING_LOTS":
            const all = payload.map((item) => {
                return Math.min(...item.info.map((p) => p.lots_available));
            });
            const parkingLots = all.reduce((a, b) => a + b, 0) / payload.length;
            return {
                ...state,
                parkingLots: Number(parkingLots.toFixed(0)),
            };
        case "SET_SITE_REVIEWS":
            return {
                ...state,
                reviews: payload,
            };
        case "SHOW_PRICE_CALCULATOR":
            return {
                ...state,
                showPriceCalculator: payload,
            };
        default:
            return state;
    }
};

export default clientAuth;
