import React from 'react'; 
import{Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'; 
import MainRoute from './routing/MainRoute'
import store from './_redux/configStore' 



import './App.scss'

function App() {  
    return (
        <Provider store={store}> 
            <BrowserRouter>
                <MainRoute/>
            </BrowserRouter> 
        </Provider>
    );
}

export default App;
