import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCar, editCar, getVehicles } from "../_actions";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

const EditCarLogic = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoader] = useState(false);

    const selectedCar = useSelector((state) => state.clientProfile.selectedCar);
    const show = useSelector((state) => state.clientProfile.showEditCar);

    const initialState = {
        brand: "",
        model: "",
        plate: "",
    };
    const [state, setState] = useState(initialState);

    const [editMode, setEditMode] = useState(false);

    const title = editMode
        ? t("client_profile.edit_car.title_edit")
        : t("client_profile.edit_car.title");
    const submit_btn = editMode
        ? t("client_profile.edit_car.submit_edit")
        : t("client_profile.edit_car.submit");

    useEffect(() => {
        if (Object.keys(selectedCar).length !== 0 && show) {
            setEditMode(true);
            setState(selectedCar);
        }
        if (Object.keys(selectedCar).length === 0) {
            setState(initialState);
            setEditMode(false);
        }
    }, [selectedCar, show]);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };
    const setSelectedCar = (value) => {
        dispatch({
            type: "SET_SELECTED_CAR",
            payload: value,
        });
    };
    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        if (editMode) {
            editCar(data, dispatch).then((response = {}) => {
                setLoader(false);
                if (response.status === 204) {
                    togglePopUp("showProfile", true);
                    togglePopUp("showEditCar", false);
                    setSelectedCar({});
                    getVehicles(dispatch);
                }
                if (response.errors) {
                    setErrors(response.errors);
                }
            });
        } else {
            createCar(data, dispatch).then((response = {}) => {
                setLoader(false);
                if (response.status === 201) {
                    // togglePopUp("showProfile", true);
                    togglePopUp("showEditCar", false);
                    setSelectedCar({});
                    getVehicles(dispatch);
                }
                if (response.errors) {
                    setErrors(response.errors);
                }
            });
        }
    };

    return { state, setSelectedCar, togglePopUp, onSubmit, loading, title, submit_btn };
};

export default EditCarLogic;
