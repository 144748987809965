import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import EditParkingsSchema from "../../../../_validations/EditParkingsSchema";

import EditLogic from "./EditLogic";

import st from "./styles.module.scss";

const Edit = () => {
    const { t } = useTranslation();
    const show = useSelector((state) => state.singleParking.showEdit);

    const { openParkingEdit, state, onSubmit, loading, parkingId, clearForm } = EditLogic();

    return (
        <>
            <div className={"pop  " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img className={st.close} src={ClosePopup} onClick={() => openParkingEdit()} />
                    <div className={st.summary}>
                        <Formik
                            enableReinitialize
                            initialValues={state}
                            validationSchema={EditParkingsSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValidating, isValid, dirty }) => {
                                return (
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h3>
                                                    <strong>
                                                        {parkingId
                                                            ? t("parkings.single.edit_title")
                                                            : t("parkings.single.create_title")}
                                                    </strong>
                                                </h3>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='name'
                                                        type='text'
                                                        label={t("parkings.single.name")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='phone'
                                                        type='text'
                                                        label={t("parkings.single.phone")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='lots'
                                                        type='text'
                                                        label={t("parkings.single.lots")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='address'
                                                        type='text'
                                                        label={t("parkings.single.address")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='city'
                                                        type='text'
                                                        label={t("parkings.single.city")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='street'
                                                                type='text'
                                                                label={t("parkings.single.street")}
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='number'
                                                                type='text'
                                                                label={t(
                                                                    "parkings.single.street_number"
                                                                )}
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button
                                            disabled={loading}
                                            type='submit'
                                            className='btn btn-submit me-2'
                                        >
                                            {t("parkings.single.submit_btn")}
                                        </button>
                                        {!parkingId && (
                                            <button
                                                type='button'
                                                onClick={clearForm}
                                                className='btn simple-btn'
                                            >
                                                {t("parkings.single.clear_btn")}
                                            </button>
                                        )}

                                        <ButtonLoader show={loading} />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Edit;
