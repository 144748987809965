import React from "react";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { TextField } from "formik-material-ui";
import { useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";

import clientCarSchema from "../../../../_validations/clientCarSchema";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";
import st from "../styles.module.scss";

import EditCarLogic from "./EditCarLogic";

const EditCar = () => {
    const { t } = useTranslation();

    const show = useSelector((state) => state.clientProfile.showEditCar);

    const { state, setSelectedCar, togglePopUp, onSubmit, loading, title, submit_btn } =
        EditCarLogic();
    return (
        <>
            <div className={st.small_popup + " " + st.info_popup + " pop  " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => {
                            togglePopUp("showEditCar", false);
                            setSelectedCar({});
                        }}
                    />
                    <div className={st.form_wrapper}>
                        <br />
                        <h3>{title}</h3>
                        <Formik
                            enableReinitialize
                            initialValues={state}
                            validationSchema={clientCarSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, isValid, dirty }) => {
                                return (
                                    <Form className='client_forms'>
                                        <Field
                                            name='brand'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            label={t("client_profile.edit_car.brand")}
                                        ></Field>

                                        <Field
                                            name='model'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            label={t("client_profile.edit_car.model")}
                                        ></Field>

                                        <Field
                                            name='plate'
                                            type='text'
                                            className='input_label'
                                            label={t("client_profile.edit_car.plate")}
                                            component={TextField}
                                        ></Field>
                                        <br />
                                        <button
                                            disabled={loading}
                                            type='submit'
                                            className='green_btn_h'
                                            style={{ width: "100%" }}
                                        >
                                            {submit_btn}
                                        </button>
                                        <br />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCar;
