import * as yup from "yup";

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Schema = yup.object().shape({
    first_name: yup.string().required("Requis!"),
    last_name: yup.string().required("Requis!"),
    email: yup.string().email("Еmail non valide !").required("Requis!"),
    phone: yup.string().nullable().required("Requis!"),
    birth_date: yup.string().nullable().required("Requis!"),
    city: yup.string().nullable().required("Requis!"),
    street: yup.string().nullable().required("Requis!"),
    street_number: yup.string().nullable().required("Requis!"),
    district: yup.string().nullable().required("Requis!"),
    post_code: yup.string().nullable().required("Requis!"),
    insurance_number: yup.string().nullable().required("Requis!"),
});

export default Schema;
