import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { Formik, Field, Form } from "formik";
import { Select, TextField } from "formik-material-ui";
import { MenuItem, FormControl } from "@material-ui/core";
import DatesLogic from "./DatesLogic";

import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import calendarNew from "../../../../images/icons/calendarNew.svg";

import MomentUtils from "@date-io/moment";
import frLocale from "moment/locale/fr";
// import enLocale from "moment/locale/en-gb";

import st from "./styles.module.scss";

const Dates = () => {
    const { t } = useTranslation();

    const { aviableTime, dates, setDates, disableSumbit, onSubmit, customErrors, onChange } = DatesLogic();

    useEffect(() => {
        // moment.locale("en-gb", enLocale);
        // moment.updateLocale("en-gb", {
        //     week: {
        //         dow: 1,
        //     },
        // });
        moment.locale("fr", frLocale);
        moment.updateLocale("fr", {
            week: {
                dow: 1,
            },
        });
    }, []);

    const { departure_at, arrival_at, arrival_at_time, departure_at_time } = dates;

    const [openedCalendar, setOpenedCalendar] = useState({
        arrival_at: false,
        departure_at: false,
    });

    const openCalendar = (calendar, value) => {
        setOpenedCalendar((prevState) => {
            if (!calendar) {
                return {
                    arrival_at: value,
                    departure_at: value,
                };
            }

            return {
                ...prevState,
                [calendar]: value,
            };
        });
    };

    return (
        <div className={st.dates_picker + "client_forms"}>
            <div className={st.result_wrapper}>
                <div style={{ color: "red" }}>
                    {customErrors && t("client_booking.step_1.error_dates")}
                </div>
            </div>
            <Formik enableReinitialize initialValues={dates} onSubmit={onSubmit}>
                {({ errors, touched, isValidating, isValid, dirty, setFieldValue }) => {
                    return (
                        <Form>
                            <div className={st.row}>
                                <div className={st.label}>
                                    {t("client_booking.step_1.title_drop_off")}
                                </div>
                            </div>
                            <div className={st.row}>
                                <div className={st.col}>
                                    <div
                                        className={st.date_holder}
                                        onClick={() => {
                                            openCalendar("departure_at", true);
                                            openCalendar("arrival_at", false);
                                        }}
                                    >
                                        <img src={calendarNew} />
                                        {departure_at
                                            ? departure_at.format("DD-MM-YY")
                                            : t("client_booking.step_1.dateFormat")}
                                    </div>
                                    <div
                                        className={
                                            st.calendar_picker_holder +
                                            " " +
                                            (openedCalendar.departure_at ? st.show : "")
                                        }
                                    >
                                        <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                            libInstance={moment}
                                        >
                                            <DatePicker
                                                variant='static'
                                                openTo='date'
                                                value={departure_at}
                                                onChange={(date) => {
                                                    setDates({
                                                        ...dates,
                                                        departure_at: date,
                                                        arrival_at: "",
                                                        arrival_at_time: "",
                                                    });

                                                    setFieldValue("departure_at", date);
                                                    setOpenedCalendar((prevState) => ({
                                                        ...prevState,
                                                        departure_at: false,
                                                    }));
                                                }}
                                                minDate={moment().add(1, "day")}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={st.col}>
                                    <FormControl
                                        className='full-width timeSelectInput'
                                        variant='outlined'
                                        onClick={() => {
                                            openCalendar(null, false);
                                        }}
                                    >
                                        <Field
                                            component={Select}
                                            type='text'
                                            value={departure_at_time}
                                            name='departure_at_time'
                                            onChange={(e) => {
                                                setDates({
                                                    ...dates,
                                                    departure_at_time: e.target.value,
                                                });
                                            }}
                                            disabled={!departure_at}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            <MenuItem value=''>Select</MenuItem>
                                            {aviableTime().map((item) => {
                                                return (
                                                    <MenuItem key={item.time} value={item.time}>
                                                        {item.time}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>
                                    </FormControl>
                                </div>
                            </div>
                            <div className={st.row}>
                                <div className={st.label}>{t("client_booking.step_1.return")}</div>
                            </div>
                            <div className={st.row}>
                                <div className={st.col}>
                                    <div
                                        className={st.date_holder}
                                        onClick={() => {
                                            openCalendar("arrival_at", true);
                                            openCalendar("departure_at", false);
                                        }}
                                    >
                                        <img src={calendarNew} />
                                        {arrival_at
                                            ? arrival_at.format("DD-MM-YY")
                                            : t("client_booking.step_1.dateFormat")}
                                    </div>
                                    <div
                                        className={
                                            st.calendar_picker_holder +
                                            " " +
                                            (openedCalendar.arrival_at ? st.show : "")
                                        }
                                    >
                                        <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                            libInstance={moment}
                                        >
                                            <DatePicker
                                                variant='static'
                                                openTo='date'
                                                value={arrival_at}
                                                onChange={(date) => {
                                                    setDates({
                                                        ...dates,
                                                        arrival_at: date,
                                                    });
                                                    setFieldValue("arrival_at", date);
                                                    openCalendar("arrival_at", false);
                                                }}
                                                minDate={
                                                    departure_at
                                                        ? moment(departure_at).add(1, "days")
                                                        : moment()
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={st.col}>
                                    <FormControl
                                        className='full-width timeSelectInput'
                                        variant='outlined'
                                        onClick={() => {
                                            openCalendar(null, false);
                                        }}
                                    >
                                        <Field
                                            component={Select}
                                            type='text'
                                            name='arrival_at_time'
                                            value={arrival_at_time}
                                            disabled={!arrival_at}
                                            onChange={(e) => {
                                                setDates({
                                                    ...dates,
                                                    arrival_at_time: e.target.value,
                                                });
                                                setFieldValue("arrival_at_time", e.target.value);
                                            }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            {aviableTime().map((item) => {
                                                <MenuItem value=''>
                                                    {t("client_booking.step_1.select")}
                                                </MenuItem>;
                                                return (
                                                    <MenuItem key={item.time} value={item.time}>
                                                        {item.time}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>
                                    </FormControl>
                                </div>
                            </div>
                            <div className={st.row}>
                                <div className={st.label}>{t("client_booking.step_1.promocode")}</div>
                            </div>
                            <div className={st.row}>
                                <div className={st.col}>
                                <Field
                                            name='promocode'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>
                                        </div>
                            </div>
                            <div className='bottom_links_wrapper'>
                                <button
                                    disabled={disableSumbit}
                                    type='submit'
                                    className='green_btn_h'
                                >
                                    {t("client_booking.next")}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default Dates;
