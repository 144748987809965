// freeDaysForApproval;

const initialState = {
    showForApproval: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SHOW_FOR_APPROVAL_POPUP":
            return {
                ...state,
                showForApproval: payload,
            };
        default:
            return state;
    }
};

export default reducer;
