import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { resetPassword } from "./_actions";

const ResetPasswordLogic = (history) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        password: "",
        password_confirmation: "",
    });

    const [email, setEmail] = useState("some@email.com");

    useEffect(() => {
        const { search } = history.location;
        const params = new URLSearchParams(search);
        const token = params.get("token");
        const email = params.get("email").split(" ").join("+");

        setState({
            ...state,
            token,
            email,
        });

        setEmail(email);
    }, []);

    const [errors, setErrors] = useState({});

    const handleErrors = (error) => {
        setErrors({ ...errors, password: error });
    };

    const [rulesCompleted, setRulesCompleted] = useState(false);

    const [loading, setLoader] = useState(false);

    const [passType, setPassType] = useState({
        password: false,
        password_confirmation: false,
    });

    const togglePassType = (name) =>
        setPassType({
            ...passType,
            [name]: !passType[name],
        });

    const onChange = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setLoader(true);

        resetPassword(state, dispatch).then((result = {}) => {
            setLoader(false);
            const { status } = result;
            if (status === 200) {
                history.push("/");
            }
            if (result.errors) {
                setErrors(result.errors);
            }
        });
    };

    return {
        onSubmit,
        setRulesCompleted,
        rulesCompleted,
        loading,
        errors,
        togglePassType,
        passType,
        state,
        onChange,
        handleErrors,
        email,
    };
};

export default ResetPasswordLogic;
