import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { getReviews, getParkingSlots } from "./_actions";
import Disptacher from "./_dispatcher";

const HomeLogic = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { togglePopUp } = Disptacher();

    useEffect(() => {
        // getReviews(dispatch);
        if (history.location.pathname === "/reset-password") {
            togglePopUp("showResetPassword", true);
        }
        getParkingSlots(dispatch);
    }, []);

    return {};
};

export default HomeLogic;
