import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";

import PricesLogic from "./PricesLogic";

import st from "./styles.module.scss";

const Prices = () => {
    const { t } = useTranslation();
    const { state, loading, onChange, onSubmit } = PricesLogic();

    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    return (
        <>
            <div className='page-header top-section-list'>
                <h2 className='title'> {t("prices.title")}</h2>
            </div>
            <div className='container-fluid wrapper'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className={st.price_wrapper + " bg pb-0 mb-5"}>
                            <div className='form-wrapper'>
                                <form noValidate onSubmit={onSubmit}>
                                    <div className='row'>
                                        {state.map((item, i) => {
                                            return (
                                                <>
                                                    <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                                        <div className='input-sign-wrap mb-3'>
                                                            <label>
                                                                {i < 40
                                                                    ? " Day " + item.day
                                                                    : "40+"}
                                                            </label>
                                                            <div className='input-sign '>
                                                                <input
                                                                    type='text'
                                                                    value={item.price}
                                                                    onChange={(e) =>
                                                                        onChange(item, e)
                                                                    }
                                                                />
                                                                <div className='sign'>CHF</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className='col-md-12 text-center'>
                                        {userPermitions["update_price"] && (
                                            <button
                                                disabled={loading}
                                                type='submit'
                                                className='btn btn-submit'
                                            >
                                                {t("prices.submit_btn")}
                                            </button>
                                        )}
                                        <ButtonLoader show={loading} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Prices;
