import { useDispatch } from "react-redux";
import Disptacher from "../_dispatcher";

const Logic = () => {
    const { resetBookingForm } = Disptacher();

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
        slidesToScroll: 1,
    };

    return { sliderSettings, resetBookingForm };
};

export default Logic;
