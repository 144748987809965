import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-scroll";

import ClosePopup from "../../../images/icons/close-popup-icon.svg";
import st from "./styles.module.scss";

import { cancelReservation, getReservations } from "./_actions";

const CancelReservation = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const show = useSelector((state) => state.clientProfile.cancelReservation);
    // const show = true;
    const selectedReservation = useSelector((state) => state.clientProfile.selectedReservation);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };

    const setSelectedReservation = (value) => {
        dispatch({
            type: "SET_SELECTED_RESERVATION",
            payload: value,
        });
    };

    const deleteReservation = () => {
        cancelReservation(selectedReservation, dispatch).then((response = {}) => {
            if (response.status === 204) {
                togglePopUp("cancelReservation", false);
                togglePopUp("cancelReservationSuccess", true);
                setSelectedReservation({});
                getReservations(dispatch);
            } else {
                togglePopUp("cancelReservation", false);
                togglePopUp("cancelReservationError", true);
                setSelectedReservation({});
                getReservations(dispatch);
            }
        });
    };
    return (
        <>
            <div
                className={
                    st.cancel_popup +
                    " " +
                    st.info_popup +
                    " pop client_forms " +
                    (show ? "show" : "")
                }
            >
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => {
                            togglePopUp("cancelReservation", false);
                            togglePopUp("showProfile", true);
                        }}
                    />
                    <div className={st.form_wrapper + " text-center"}>
                        <h3 className='mb-2'>{t("cansel_reservation.title")}!</h3>
                        <p className={st.introText + " mb-4"}>
                            {t("cansel_reservation.info_text")}
                        </p>
                        <button
                            type='button'
                            onClick={() => {
                                deleteReservation();
                            }}
                            className='green_btn_h'
                        >
                            {t("cansel_reservation.delete")}
                        </button>
                        <button
                            type='button'
                            onClick={() => {
                                togglePopUp("cancelReservation", false);
                                togglePopUp("showProfile", true);
                            }}
                            className='grey_btn_h'
                        >
                            {t("cansel_reservation.cancel")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CancelReservation;
