import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { columnHeaders, tableFilters, headerResultLabel } from "./list_helper";

import { getClientsList } from "./_actions";

const ListLogic = () => {
    const urlBase = "/dashboard/clients/";

    const dispatch = useDispatch();

    const querySting = useSelector((state) => state.listTableReducer.query);
    const list = useSelector((state) => state.clientsList.items);

    const [componentMounted, setComponentMounted] = useState(false);
    useEffect(() => {
        setComponentMounted(true);
    }, []);

    useEffect(() => {
        if (querySting && componentMounted) {
            getClientsList(querySting, dispatch);
        }
    }, [querySting]);

    useEffect(() => {
        const column = columnHeaders();

        dispatch({
            type: "SET_TABLE_FILTERS_SORT",
            payload: { column, tableFilters, headerResultLabel },
        });
    }, []);

    const openInvite = () => {
        dispatch({
            type: "SHOW_INVITE_EMPLOYEE_POPUP",
            payload: true,
        });
    };

    return { urlBase, openInvite, list };
};

export default ListLogic;
