import React from "react";
import { useSelector } from "react-redux";

import st from "./styles.module.scss";

const PageLoader = () => {
    const showLoader = useSelector((state) => state.pageLoader.show);
    return (
        <div className={st.page_loader_wrapper + " " + (showLoader ? st.show : "")}>
            <div className={st.loader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default PageLoader;
