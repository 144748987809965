// pageLoader

const initialState = {
    show: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SHOW_PAGE_LOADER":
            return {
                show: payload,
            };
        default:
            return state;
    }
};

export default reducer;
