import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { calcPrice, checkForPlaces } from "../_actions";
import { formatDates } from "../../../../helpers/formatDates";
import dispatcher from "../_dispatcher";

import { getCountriesList } from "../_actions";

const Logic = () => {
    const dispatch = useDispatch();
    const active_step = useSelector((state) => state.clientBooking.active_step);
    const reservation = useSelector((state) => state.clientBooking.reservation);
    const disableSumbit = useSelector((state) => state.clientBooking.disableSumbit);
    const dates = useSelector((state) => state.clientBooking.dates);
    const isAuth = useSelector((state) => state.clientAuth.token_client);

    const { setBookingScreen } = dispatcher();

    useEffect(() => {
        getCountriesList(dispatch);
    }, []);

    const active = Number(active_step.toFixed());

    let hasFreeSpot = false;
    const submitDates = () => {
        checkForPlaces(formatDates(dates), dispatch).then((response = {}) => {
            if (response.status === 200) {
                const resp = response.data.data;
                for (let i = 0; i < Object.keys(resp).length; i++) {
                    if (resp[Object.keys(resp)[i]].available) {
                        hasFreeSpot = true;
                        break;
                    }
                }
                if (hasFreeSpot) {
                    if (!isAuth) {
                        setBookingScreen("active_step", 2.2);
                    } else {
                        setBookingScreen("active_step", 2.9);
                    }

                    setBookingScreen("complete_first_step", true);
                    setBookingScreen("enable_second_step", true);
                }
            }
        });
    };
    return {
        active,
        reservation,
        disableSumbit,
    };
};

export default Logic;
