import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";

import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";

import { personalInfo } from "../../../../_validations/clientRegisterBooking";

import st from "./styles.module.scss";
import RegisterUserLogic from "./Logic";

const RegisterUser = () => {
    const { t } = useTranslation();
    const {
        state,
        passType,
        togglePassType,
        onSubmit,
        setBookingScreen,

        loading,
        phoneCode,
        handleCountryChange,
        countriesList,
        country,
        disableSumbit,
        onChange,
    } = RegisterUserLogic();

    return (
        <>
            <h4 className={st.title}>{t("client_booking.step_2.sec_title")} </h4>
            <Formik
                enableReinitialize
                initialValues={state}
                validationSchema={personalInfo}
                onSubmit={onSubmit}
            >
                {({ errors, touched, setFieldValue, setTouched }) => {
                    Object.keys(errors).map((item) => {
                        errors[item] = t(errors[item]);
                        return errors[item];
                    });

                    return (
                        <Form className='client_forms'>
                            <div className={st.form_wrapper}>
                                <h5 className={st.section_title}>
                                    {t("client_booking.step_2.reg_title_form")}{" "}
                                </h5>
                                <div className={st.row}>
                                    <div className={st.col}>
                                        {countriesList.length > 0 && (
                                            <>
                                                <label className='label_custom' htmlFor=''>
                                                    {t("client_booking.step_2.country") + "*"}
                                                </label>
                                                <Field
                                                    name='country'
                                                    value={country}
                                                    className='autocomplete_label'
                                                    options={countriesList}
                                                    getOptionLabel={(option) => option.name}
                                                    component={Autocomplete}
                                                    onChange={
                                                        ((e) =>
                                                            setFieldValue(
                                                                "country_id",
                                                                e.target.value
                                                            ),
                                                        handleCountryChange)
                                                    }
                                                    onBlur={() =>
                                                        setTouched({
                                                            ["country_id"]: true,
                                                        })
                                                    }
                                                    renderInput={(params) => {
                                                        return (
                                                            <MuiTextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: "none",
                                                                }}
                                                                name='country_id'
                                                                error={
                                                                    touched["country_id"] &&
                                                                    errors["country_id"]
                                                                }
                                                                helperText={
                                                                    touched["country_id"] &&
                                                                    errors["country_id"]
                                                                }
                                                            />
                                                        );
                                                    }}
                                                ></Field>
                                            </>
                                        )}
                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.first_name") + "*"}
                                        </label>
                                        <Field
                                            name='first_name'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.last_name") + "*"}
                                        </label>
                                        <Field
                                            name='last_name'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.email") + "*"}
                                        </label>
                                        <Field
                                            name='email'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.phone") + "*"}
                                        </label>
                                        <div className='phone_code_wrapper without_label'>
                                            <Field
                                                name='phoneCode'
                                                type='text'
                                                disabled
                                                value={phoneCode}
                                                className='input_label phone_code'
                                                component={TextField}
                                            ></Field>
                                            <Field
                                                name='phone'
                                                type='text'
                                                placeholder={t("client_booking.phone_placeholder")}
                                                className='input_label phone'
                                                component={TextField}
                                                onChange={onChange}
                                            ></Field>
                                        </div>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.password") + "*"}
                                        </label>
                                        <div className='password_input_label'>
                                            <Field
                                                name='password'
                                                type={passType ? "text" : "password"}
                                                className='input_label'
                                                component={TextField}
                                                onChange={onChange}
                                            ></Field>
                                            <i
                                                className='password_icon fas fa-eye'
                                                onClick={togglePassType}
                                            ></i>
                                        </div>
                                    </div>

                                    <div className={st.col}>
                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.street") + "*"}
                                        </label>
                                        <Field
                                            name='street'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.street_number") + "*"}
                                        </label>
                                        <Field
                                            name='street_number'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.post_code") + "*"}
                                        </label>
                                        <Field
                                            name='post_code'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.city") + "*"}
                                        </label>
                                        <Field
                                            name='city'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                        ></Field>

                                        <label className='label_custom' htmlFor=''>
                                            {t("client_booking.step_2.company") + "*"}
                                        </label>
                                        <Field
                                            name='company'
                                            type='text'
                                            className='input_label'
                                            component={TextField}
                                            onChange={onChange}
                                            // placeholder={t("client_booking.company_placeholder")}
                                        ></Field>
                                    </div>
                                </div>
                            </div>
                            <div className='bottom_links_wrapper'>
                                <button
                                    type='button'
                                    className='btn_back '
                                    onClick={() => setBookingScreen("active_step", 2.2)}
                                >
                                    {t("client_booking.back")}
                                </button>
                                <button
                                    type='submit'
                                    disabled={disableSumbit || loading}
                                    className='green_btn_h'
                                >
                                    {t("client_booking.next")}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default RegisterUser;
