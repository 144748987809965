import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { columnHeaders, tableFilters, headerResultLabel } from "./list_helper";

import { getEmployeesList } from "./_actions";
import { deleteSingleEmpoloyee } from "../Single/_actions";

const ListLogic = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const querySting = useSelector((state) => state.listTableReducer.query);
    const list = useSelector((state) => state.employeesList.items);
    const confirmDelete = useSelector((state) => state.confirmDelete);

    const [componentMounted, setComponentMounted] = useState(false);
    useEffect(() => {
        setComponentMounted(true);
        return () => {
            dispatch({
                type: "SET_LIST_TABLE_QUERY",
                payload: "",
            });
        };
    }, []);
    useEffect(() => {
        if (querySting && componentMounted) {
            getEmployeesList(querySting, dispatch);
        }
    }, [componentMounted, querySting]);

    useEffect(() => {
        const column = columnHeaders();

        dispatch({
            type: "SET_TABLE_FILTERS_SORT",
            payload: { column, tableFilters, headerResultLabel },
        });
    }, []);

    useEffect(() => {
        const { location, confirm, id } = confirmDelete;
        if (confirm && location === "employees_list") {
            deleteSingleEmpoloyee(id, dispatch).then((result) => {
                dispatch({
                    type: "SET_DELETE_CONFIRM",
                    payload: {
                        ...confirmDelete,
                        confirm: false,
                    },
                });
                getEmployeesList(querySting, dispatch);
            });
        }
    }, [confirmDelete]);

    const deleteEmployee = (id, fullName) => {
        let text =
            t("clients.delete.question_1") +
            " “" +
            fullName +
            "” " +
            t("clients.delete.question_2");

        dispatch({
            type: "SET_DELETE_CONFIRM",
            payload: {
                show: true,
                text: text,
                location: "employees_list",
                id: id,
            },
        });
    };

    const openInvite = () => {
        dispatch({
            type: "SHOW_INVITE_EMPLOYEE_POPUP",
            payload: true,
        });
    };
    const openEmployeePreview = (id) => {
        dispatch({
            type: "SHOW_PREVIEW_EMPLOYEE_POPUP",
            payload: { id, status: true },
        });
    };

    return { deleteEmployee, openInvite, list, openEmployeePreview };
};

export default ListLogic;
