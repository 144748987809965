import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en";
import { TRANSLATIONS_DE } from "./de";
import { TRANSLATIONS_FR } from "./fr";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            lng: "fr",
            fallbackLng: "fr",
            en: {
                translation: TRANSLATIONS_EN,
            },
            de: {
                translation: TRANSLATIONS_DE,
            },
            fr: {
                translation: TRANSLATIONS_FR,
            },
        },
    });

export default i18n;
