// clientAuth

const initialState = {
    token_client: ("; " + document.cookie).split("; accessToken_client=").pop().split(";").shift(),
    refresh_token_client: ("; " + document.cookie)
        .split("; refreshToken_client=")
        .pop()
        .split(";")
        .shift(),
    showLogin: false,
    showForgotPassword: false,
    showCheckEmail: false,
    showResetPassword: false,
    showErrorEmail: false,
    showSuccessEmail: false,
    showTerms: false,
    showPrivacy: false,
};

const delete_cookie = (name) => {
    document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=." + document.domain;
};

const clientAuth = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "OPEN_POPUP_AUTH":
            const { name, value } = payload;
            return {
                ...state,
                [name]: value,
            };

        case "LOGIN_SUCCESS_CLIENT":
            document.cookie =
                "accessToken_client=" +
                payload.access_token +
                "; Path=/; Domain=." +
                document.domain;
            document.cookie =
                "refreshToken_client=" +
                payload.refresh_token +
                "; Path=/; Domain=." +
                document.domain;
            // window.location.reload();
            return {
                ...state,
                token_client: payload.access_token,
                refresh_token_client: payload.refresh_token,
            };

        case "LOGOUT_CLIENT":
            delete_cookie("accessToken_client");
            delete_cookie("refreshToken_client");
            return {
                ...state,
                token_client: null,
                refresh_token_client: null,
            };

        default:
            return state;
    }
};

export default clientAuth;
