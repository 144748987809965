import React, {Children} from 'react';  
import moment from 'moment';
  


const addStyles =(children, value)=> {
    const today = moment().toDate();
    const dayOfWeek = value.getDay();
    const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0); // 6 = Saturday, 0 = Sunday
    if (!isWeekend) {
        return {
            style: {
                ...children.style,
                backgroundColor: value < today ? '#f5f5f5' : '#fff',
            },
        } 
    }else{
        return {
            style: {
                ...children.style,
                backgroundColor: '#f1f1f1',
            },
        } 
    }
} 

const ColoredDateCellWrapper = ({children, value}) => 
    React.cloneElement(Children.only(children), addStyles(children, value)); 

export default ColoredDateCellWrapper