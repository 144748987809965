import React, { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
import { Redirect, useHistory } from "react-router";

import { useSelector, useDispatch } from "react-redux";
import PrivateRoute from "../../routing/PrivateRoute";
import { DashboardRoutes } from "../../routing/DashboardRoutes";

import SideBar from "../../layout/SideBar/SideBar";
import ConfirmDelete from "../../components/ConfirmDelete/ConfirmDelete";
import PageLoader from "../../components/PageLoader/PageLoader";
import { logout } from "../../pages/Public/Login/_actions";
import { getProfile } from "./_actions";

import axios from "../../configAxios";
const Dashboard = () => {
    const history = useHistory();

    const location = useLocation();
    const dispatch = useDispatch();
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    const role = useSelector((state) => state.dashboardReducer.role);

    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        getProfile(dispatch);
    }, []);

    useEffect(() => {
        if (role === 3) {
            if (
                history.location.pathname === "/dashboard/employees" ||
                history.location.pathname === "/dashboard/parkings"
            ) {
                history.push("/dashboard/calendar");
            }
        }
    }, [role]);

    useEffect(() => {
        if (token === null || token === "") {
            logout();
        }
    }, [token]);

    axios.interceptors.request.use(
        function (config) {
            dispatch({
                type: "SHOW_PAGE_LOADER",
                payload: true,
            });

            return config;
        },
        function (error) {
            dispatch({
                type: "SHOW_PAGE_LOADER",
                payload: false,
            });
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        (res) => {
            dispatch({
                type: "SHOW_PAGE_LOADER",
                payload: false,
            });
            return res;
        },
        async (err) => {
            dispatch({
                type: "SHOW_PAGE_LOADER",
                payload: false,
            });
            return Promise.reject(err);
        }
    );
    return (
        <>
            <ConfirmDelete />
            <SideBar />
            <div className='main-container'>
                <div className='wrapper-card'>
                    <PageLoader />
                    <Switch key={location.key} location={location}>
                        {Object.keys(DashboardRoutes).map((item, i) => {
                            const permission = DashboardRoutes[item].permition;

                            return (
                                userPermitions[permission] !== "undefined" && (
                                    <PrivateRoute
                                        key={DashboardRoutes[item].path}
                                        exact={DashboardRoutes[item].exact}
                                        path={DashboardRoutes[item].path}
                                        component={DashboardRoutes[item].component}
                                    />
                                )
                            );
                        })}

                        <Redirect exact to='/dashboard/calendar' />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
