import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { addReservationClient } from "./_actions";
import { getReservationsListClient } from "../Reservations/_actions";

const ReservationsAdd = () => {
    const dispatch = useDispatch();
    const client = useSelector((state) => state.singleClient.client);
    const clientId = useSelector((state) => state.singleClient.clientId);
    const showAddReservation = useSelector((state) => state.addReservationClient.addReservation);

    const [loading, setLoader] = useState(false);

    const [state, setState] = useState({
        car: "",
        parking: "",
        valet: "",
        from: null,
        to: null,
        note: "",
        flight: "",
    });

    const [daysDuration, setDaysDuration] = useState(0);

    useEffect(() => {
        const { start, end } = state;
        if (start && end) {
            const startR = moment(start, "DD-MM-YYYY").format("YYYY-MM-DD");
            const endR = moment(end, "DD-MM-YYYY").format("YYYY-MM-DD");
            const startM = moment(startR, "YYYY-MM-DD");
            const endM = moment(endR, "YYYY-MM-DD");
            if (moment.duration(endM.diff(startM)).asDays() === 0) {
                setDaysDuration(1);
            } else {
                setDaysDuration(moment.duration(endM.diff(startM)).asDays() + 1);
            }
        }
    }, [state]);

    const openAddReservation = (id) => {
        dispatch({
            type: "SHOW_ADD_RESERVATION_CLIENT",
            payload: false,
        });
    };

    const querySting = useSelector((state) => state.listTableReducer.query);
    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        addReservationClient(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 204) {
                getReservationsListClient(clientId, querySting, dispatch);
                dispatch({
                    type: "SHOW_ADD_RESERVATION_CLIENT",
                    payload: false,
                });
            }

            if (response.errors) {
                let moderatedErrors = { error: "" };
                for (const property in response.errors) {
                    moderatedErrors = {
                        ...moderatedErrors,
                        [property]: response.errors[property].toString(),
                    };
                }

                setErrors(response.errors);
            }
        });
    };

    return {
        openAddReservation,
        state,
        onSubmit,
        loading,
        daysDuration,
    };
};

export default ReservationsAdd;
