// singleEmployee

const initialState = {
    showPreview: false,
    employeeId: "",
    showEdit: false,
    employee: {},
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    const { id, status } = payload || {};
    switch (type) {
        case "SHOW_PREVIEW_EMPLOYEE_POPUP":
            return {
                ...state,
                showPreview: status,
                employeeId: id,
            };
        case "SHOW_EDIT_EMPLOYEE_POPUP":
            return {
                ...state,
                showEdit: status,
                employeeId: id,
            };
        case "GET_SINGLE_EMPOYEE":
            return {
                ...state,
                employee: payload,
            };
        default:
            return state;
    }
};

export default reducer;
