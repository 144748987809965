import * as yup from "yup";

const Schema = yup.object().shape({
    email: yup.string().email("Еmail non valide !").required("Requis!"),
    password: yup
        .string()
        // .min(4, 'Паролата трябва да бъде между 4 и 15 символа')
        // .max(15, 'Паролата трябва да бъде между 4 и 15 символа')
        .required("Requis!")
        .min(6, "Doit comporter au moins 6 symboles"),
});

export default Schema;
