import React from "react";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import Logic from "./Logic";
import arrowNextSteps from "../../../../images/icons/arrowNextSteps.svg";
import st from "./styles.module.scss";

const Booking = () => {
    const { t } = useTranslation();

    const {
        enable_second_step,
        enable_third_step,
        active,
        changeStep,
        isAuth,
        aditional_payment,
        complete_first_step,
        complete_second_step,
    } = Logic();

    return (
        !aditional_payment && (
            <div className={st.nav_wrapper}>
                <ul className={st.nav}>
                    <li
                        className={
                            (active === 1 ? st.active : "") +
                            " " +
                            (complete_first_step ? st.completed : "")
                        }
                    >
                        <button type='button' onClick={() => changeStep(1)}>
                            <div className={st.cirlce}></div>

                            <span> {t("client_booking.side_bar.step_1")}</span>
                        </button>
                    </li>
                    <li>
                        <img src={arrowNextSteps} />
                    </li>
                    <li
                        className={
                            (active === 2 ? st.active : "") +
                            " " +
                            (complete_second_step || active === 3 ? st.completed : "")
                        }
                    >
                        <button
                            type='button'
                            disabled={!enable_second_step}
                            onClick={!isAuth ? () => changeStep(2.2) : () => changeStep(2.9)}
                        >
                            <div className={st.cirlce}></div>

                            <span>{t("client_booking.side_bar.step_2")}</span>
                        </button>
                    </li>
                    <li>
                        <img src={arrowNextSteps} />
                    </li>
                    <li className={active === 3 ? st.active : ""}>
                        <button
                            type='button'
                            disabled={!enable_third_step}
                            onClick={() => changeStep(2.9)}
                        >
                            <div className={st.cirlce}></div>

                            <span>{t("client_booking.side_bar.step_3")}</span>
                        </button>
                    </li>
                </ul>
            </div>
        )
    );
};

export default Booking;
