import React from "react";
import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";

import { Select } from "formik-material-ui";
import { MenuItem, FormControl, InputLabel } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

import { TextField } from "formik-material-ui";

import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";

import ButtonLoader from "../../../../../components/ButtonLoader/ButtonLoader";
import ClosePopup from "../../../../../images/icons/close-popup-icon.svg";

import ReservationClient from "../../../../../_validations/ReservationClient";

import EditLogic from "./ReservationsAddLogic";

import st from "./styles.module.scss";

const ReservationsAdd = () => {
    const show = useSelector((state) => state.addReservationClient.addReservation);

    const { openAddReservation, state, onSubmit, loading, daysDuration } = EditLogic();

    return (
        <>
            <div className={"pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => openAddReservation()}
                    />
                    <h3></h3>
                    <div className={st.summary}>
                        <Formik
                            enableReinitialize
                            initialValues={state}
                            validationSchema={ReservationClient}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValidating, isValid, dirty }) => {
                                return (
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h3>
                                                    <strong>Add new reservation</strong>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12 mb-3'>
                                                <FormControl
                                                    className='full-width select-input'
                                                    variant='outlined'
                                                    error={touched["user"] && !!errors["user"]}
                                                >
                                                    <InputLabel>Choose Car: *</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        type='text'
                                                        name='car'
                                                    >
                                                        <MenuItem value='male'>Jhon Dow</MenuItem>
                                                        <MenuItem value='female'>Jhon Dow</MenuItem>
                                                    </Field>
                                                    {errors.user && (
                                                        <FormHelperText>
                                                            {errors.user}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                <FormControl
                                                    className='full-width select-input'
                                                    variant='outlined'
                                                    error={touched["user"] && !!errors["user"]}
                                                >
                                                    <InputLabel>Parking: *</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        type='text'
                                                        name='parking'
                                                    >
                                                        <MenuItem value='male'>Jhon Dow</MenuItem>
                                                        <MenuItem value='female'>Jhon Dow</MenuItem>
                                                    </Field>
                                                    {errors.user && (
                                                        <FormHelperText>
                                                            {errors.user}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <FormControl
                                                    className='full-width select-input'
                                                    variant='outlined'
                                                    error={touched["user"] && !!errors["user"]}
                                                >
                                                    <InputLabel>Valet: *</InputLabel>
                                                    <Field
                                                        component={Select}
                                                        type='text'
                                                        name='valet'
                                                    >
                                                        <MenuItem value='male'>Jhon Dow</MenuItem>
                                                        <MenuItem value='female'>Jhon Dow</MenuItem>
                                                    </Field>
                                                    {errors.user && (
                                                        <FormHelperText>
                                                            {errors.user}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </div>
                                        <MuiPickersUtilsProvider
                                            libInstance={moment}
                                            utils={MomentUtils}
                                            locale='en-gb'
                                        >
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <Field
                                                        autoOk={true}
                                                        showTodayButton={true}
                                                        format='DD.MM.YYYY'
                                                        name='from'
                                                        type='text'
                                                        label='Arrival at: *'
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    <i className='far fa-calendar-alt'></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        className='full-width date-input'
                                                        InputLabelProps={{ shrink: true }}
                                                        component={DatePicker}
                                                        inputVariant='outlined'
                                                    ></Field>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <Field
                                                        autoOk={true}
                                                        showTodayButton={true}
                                                        format='DD.MM.YYYY'
                                                        name='to'
                                                        type='text'
                                                        label='Departure at: *'
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    <i className='far fa-calendar-alt'></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        className='full-width date-input'
                                                        InputLabelProps={{ shrink: true }}
                                                        component={DatePicker}
                                                        inputVariant='outlined'
                                                    ></Field>
                                                </div>
                                            </div>
                                        </MuiPickersUtilsProvider>
                                        <div className='row'>
                                            <div className='col-6 mb-3'>
                                                <p className=''>
                                                    Duration:
                                                    <strong>
                                                        {daysDuration}
                                                        {daysDuration === 1 ? " day" : " days"}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='input-border textarea mb-3'>
                                                    <Field
                                                        name='note'
                                                        type='text'
                                                        multiline={true}
                                                        label='Note:'
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='flight'
                                                        type='text'
                                                        label='Flight number:'
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            disabled={loading}
                                            type='submit'
                                            className='btn btn-submit me-2'
                                        >
                                            Create
                                        </button>
                                        <button type='button' className='btn simple-btn'>
                                            Clear
                                        </button>

                                        <ButtonLoader show={loading} />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReservationsAdd;
