// addReservationClient

const initialState = {
    addReservation: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SHOW_ADD_RESERVATION_CLIENT":
            return {
                ...state,
                addReservation: payload,
            };

        default:
            return state;
    }
};

export default reducer;
