import axios from "../../../configAxios.js";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

export const getStatistics = (month, year, dispatch) => {
    return axios
        .get("dashboard?year=" + year + "&month=" + month)
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_DASHBOARD_STATISTICS",
                    payload: data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
