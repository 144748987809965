import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import "../../../../../languages/i18nextConf";

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import ListTable from "../../../../../components/ListTable/ListTable";

import ReservationsLogic from "./ReservationsLogic";

import st from "./styles.module.scss";
import Preview from "../../../Reservations/Single/Preview";
import Edit from "../../../Reservations/Single/Edit";
const Reservations = ({}) => {
    const { t } = useTranslation();
    const { list, openReservationPreview } = ReservationsLogic();

    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    return (
        <>
            {userPermitions["update_reservation"] && <Edit />}
            {userPermitions["view_reservation"] && <Preview />}

            <div className={st.list_table + " wrapper"}>
                <ListTable>
                    {list.map((item) => {
                        const photos = item.vehicle_photos || {};
                        const { data: photoArr = [] } = photos;
                        return (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.created_at}</td>
                                <td>{item.departure_at}</td>
                                <td>{item.arrival_at}</td>
                                <td>
                                    {item.days}
                                    {item.days === 1 ? " day" : " days"}
                                </td>
                                <td>
                                    {item.status === "awaiting" && (
                                        <span className='box box_lightBlue'>{item.status}</span>
                                    )}
                                    {item.status === "active" && (
                                        <span className='box box_green'>Arrived</span>
                                    )}
                                    {item.status === "done" && (
                                        <span className='box box_orange'>{item.status}</span>
                                    )}
                                    {item.status === "not_come" && (
                                        <span className='box box_yellow'>{item.status}</span>
                                    )}
                                    {item.status === "not_done" && (
                                        <span className='box box_ping'>{item.status}</span>
                                    )}
                                    {item.status === "declined" && (
                                        <span className='box box_red'>Canceled</span>
                                    )}
                                </td>
                                <td>
                                    <td>
                                        {item.payment_status === "paid" && (
                                            <span className='box box_green'>
                                                {item.payment_status}
                                            </span>
                                        )}
                                        {item.payment_status === "pending" && (
                                            <span className='box box_lightBlue'>
                                                {item.payment_status}
                                            </span>
                                        )}
                                        {item.payment_status === "admin-reservation" && (
                                            <span className='box box_orange'>
                                                {item.payment_status}
                                            </span>
                                        )}
                                    </td>
                                </td>
                                <td>
                                    {photoArr.length > 0 && (
                                        <SimpleReactLightbox>
                                            <SRLWrapper>
                                                {photoArr.map((src, i) => {
                                                    return (
                                                        <a
                                                            href={src.original_url}
                                                            key={i}
                                                            style={{
                                                                display:
                                                                    i > 1 ? "none" : "inline-block",
                                                            }}
                                                        >
                                                            <img src={src.original_url} />
                                                        </a>
                                                    );
                                                })}
                                                {photoArr.length > 2 ? (
                                                    <span
                                                        style={{
                                                            display: "inline-block",
                                                        }}
                                                    >
                                                        + {photoArr.length - 2}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </SRLWrapper>
                                        </SimpleReactLightbox>
                                    )}
                                    {photoArr.length === 0 && t("reservations.table.none")}
                                </td>
                                <td>{item.vat}</td>
                                <td>{item.order_total}</td>
                                <td>{item.flight_number}</td>
                                <td>
                                    {item.vehicle
                                        ? item.vehicle.plate
                                        : t("reservations.table.none")}
                                </td>
                                <td>
                                    {item.unpaid_notification ? (
                                        <>
                                            <i className='far fa-paper-plane'></i>{" "}
                                            {t("reservations.table.send")}
                                        </>
                                    ) : (
                                        t("reservations.table.notsend")
                                    )}
                                </td>
                                <td>
                                    {userPermitions["view_reservation"] && (
                                        <div className={st.actions_icons}>
                                            <i
                                                onClick={() => openReservationPreview(item.id)}
                                                className='fas fa-eye'
                                            ></i>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                    {list.length === 0 && (
                        <tr>
                            <td colSpan={12} className='text-center'>
                                {t("reservations.table.noResults")}
                            </td>
                        </tr>
                    )}
                </ListTable>
            </div>
        </>
    );
};

export default Reservations;
