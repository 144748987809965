import i18n from "../../../../languages/i18nextConf";

export const headerResultLabel = false;
export const columnHeaders = () => {
    return [
        {
            name: "id",
            label: i18n.t("promocodes.table.id"),
            sort: true,
            status: "desc",
        },
        {
            name: "code",
            label: i18n.t("promocodes.table.code"),
            sort: false,
        },
        {
            name: "type",
            label: i18n.t("promocodes.table.type"),
            sort: false,
        },
        {
            name: "value",
            label: i18n.t("promocodes.table.value"),
            sort: false,
        },

        {
            name: "active",
            label: i18n.t("promocodes.table.active"),
            sort: false,
        },
        {
            name: "number_of_uses",
            label: i18n.t("promocodes.table.number_of_uses"),
            sort: false,
        },
        {
            name: "from_date",
            label: i18n.t("promocodes.table.from_date"),
            sort: false,
        },
        {
            name: "to_date",
            label: i18n.t("promocodes.table.to_date"),
            sort: false,
        },

        {
            name: "action",
            label: "Actions",
            label: i18n.t("promocodes.table.action"),
            sort: false,
        },
    ];
};

export const tableFilters = {
    // first_name: {
    //     label: "Demo",
    //     filterName: "Demo",
    //     data: [
    //         {
    //             id: 1,
    //             name: "Ivan",
    //         },
    //         {
    //             id: 2,
    //             name: "Dragan",
    //         },
    //         {
    //             id: 3,
    //             name: "Petkan",
    //         },
    //     ],
    // },
};
