import axios from "../../../../configAxios.js";
import { setMessage } from "../../../../components/AlertMessage/_actions.js";

export const getSinglePromocode = (id, dispatch) => {
    return axios
        .get("/promocodes/" + id)
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_SINGLE_PROMOCODE",
                    payload: data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const createPromocode = (data, dispatch) => {
    return axios
        .post("/promocodes", data)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const updatePromocode = (data, dispatch) => {
    const { id } = data;
    return axios
        .put("/promocodes/" + id, data)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
