import { useDispatch } from "react-redux";

import dispatcher from "../_dispatcher";

const Logic = () => {
    const { showPriceCalculator } = dispatcher();
    return { showPriceCalculator };
};

export default Logic;
