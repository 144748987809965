import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { changePassword } from "../_actions";

const ResetPasswordLogic = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        current_password: "",
        password: "",
        confirm_password: "",
    });

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };
    const [loading, setLoader] = useState(false);

    const [passType, setPassType] = useState(false);
    const togglePassType = () => setPassType(!passType);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        changePassword(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 204) {
                togglePopUp("showProfile", true);
                togglePopUp("showChangePassword", false);
            }
            if (response.errors) {
                setErrors(response.errors);
            }
        });
    };

    return { state, togglePopUp, onSubmit, loading, passType, togglePassType };
};

export default ResetPasswordLogic;
