import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { resetPassword } from "../_actions";

const ResetPasswordLogic = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        password: "",
        password_confirmation: "",
    });

    const history = useHistory();
    useEffect(() => {
        if (history.location.pathname === "/reset-password") {
            const { search } = history.location;
            const params = new URLSearchParams(search);
            const token = params.get("token");
            let email = "";
            if (params.get("email")) {
                email = params.get("email").split(" ").join("+");
            }

            setState({
                ...state,
                token,
                email,
            });
        }
    }, []);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    const [loading, setLoader] = useState(false);

    const [passType, setPassType] = useState(false);
    const togglePassType = () => setPassType(!passType);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        resetPassword(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 200) {
                togglePopUp("showResetPassword", false);
                togglePopUp("showSuccessEmail", true);
            }
            if (response.errors) {
                togglePopUp("showResetPassword", false);
                togglePopUp("showErrorEmail", true);
            }
        });
    };

    return { state, togglePopUp, onSubmit, loading, passType, togglePassType };
};

export default ResetPasswordLogic;
