import axios from "../../../configAxios.js";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

export const login = (аuth, dispatch) => {
    // dispatch({
    //     type: "LOGIN_SUCCESS",
    //     payload: { token: "asdasdasd" },
    // });
    return axios
        .post("login", { ...аuth })
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "LOGIN_SUCCESS",
                    payload: result.data.data,
                });

                dispatch(setMessage(result));
            } else {
                dispatch({
                    type: "LOGOUT",
                    payload: "",
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const logout = (аuth, dispatch) => {
    dispatch({
        type: "LOGOUT",
        payload: "",
    });
};
