import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import dispatcher from "./_dispatcher";

import { getCountriesList } from "./_actions";

const Logic = () => {
    const dispatch = useDispatch();
    const complete_first_step = useSelector((state) => state.clientBooking.complete_first_step);
    const complete_second_step = useSelector((state) => state.clientBooking.complete_second_step);
    const enable_second_step = useSelector((state) => state.clientBooking.enable_second_step);
    const enable_third_step = useSelector((state) => state.clientBooking.enable_third_step);
    const active_step = useSelector((state) => state.clientBooking.active_step);
    const aditional_payment = useSelector((state) => state.clientBooking.aditional_payment);

    const vehicle_id = useSelector((state) => state.clientBooking.reservation.vehicle_id);

    const isAuth = useSelector((state) => state.clientAuth.token_client);

    useEffect(() => {
        getCountriesList(dispatch);
    }, []);

    const { setBookingScreen } = dispatcher();
    const changeStep = (step) => {
        setBookingScreen("active_step", step);
    };
    const active = Number(active_step.toFixed());
    const showStatus = (step) => {
        if (active === step) {
            return <div></div>;
        }
        if (step === 1 && complete_first_step) {
            return <i className='fas fa-check-circle'></i>;
        } else if (step === 2 && complete_second_step) {
            return <i className='fas fa-check-circle'></i>;
        }
    };
    return {
        vehicle_id,
        enable_second_step,
        enable_third_step,
        active,
        active_step,
        changeStep,
        isAuth,
        aditional_payment,
        showStatus,
    };
};

export default Logic;
