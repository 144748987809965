import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { getSingleEmployee, updateSingleEmployee } from "./_actions";
import { getEmployeesList } from "../List/_actions";

const EditLogic = () => {
    const dispatch = useDispatch();
    const employee = useSelector((state) => state.singleEmployee.employee);
    const employeeId = useSelector((state) => state.singleEmployee.employeeId);
    const show = useSelector((state) => state.singleEmployee.showEdit);
    const [loading, setLoader] = useState(false);

    const initialState = {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        insurance_number: "",
        birth_date: null,
        phone: "",
        city: "",
        district: "",
        post_code: "",
        street: "",
        street_number: "",
        role_id: 0,
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (employeeId && show) {
            getSingleEmployee(employeeId, dispatch);
        }
    }, [employeeId, show]);

    useEffect(() => {
        if (Object.keys(employee).length > 0) {
            const { birth_date = "", roles = [] } = employee;
            const { id: role_id = 0 } = roles[0] || {};
            for (const key in employee) {
                employee[key] = employee[key] === null ? "" : employee[key];
            }

            setState({
                reInit: state.reInit,
                ...employee,
                role_id,
                birth_date: birth_date.length > 0 ? birth_date : null,
            });
        }
    }, [employee]);

    const onChangeDate = (input, date, value) => {
        setState({
            ...state,
            [input]: date,
        });
    };
    const onChange = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const openEmployeeEdit = (id) => {
        dispatch({
            type: "SHOW_EDIT_EMPLOYEE_POPUP",
            payload: { id, status: false },
        });
    };

    const querySting = useSelector((state) => state.listTableReducer.query);
    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        const { birth_date, ...withoutBirthday } = data;

        const birthDate = moment(birth_date).format("YYYY-MM-DD");
        updateSingleEmployee({ ...withoutBirthday, birth_date: birthDate }, dispatch).then(
            (response = {}) => {
                setLoader(false);
                if (response.status === 204) {
                    getEmployeesList(querySting, dispatch);
                    dispatch({
                        type: "SHOW_EDIT_EMPLOYEE_POPUP",
                        payload: { id: data.id, status: false },
                    });
                }

                if (response.errors) {
                    let moderatedErrors = { error: "" };
                    for (const property in response.errors) {
                        moderatedErrors = {
                            ...moderatedErrors,
                            [property]: response.errors[property].toString(),
                        };
                    }

                    setErrors(response.errors);
                }
            }
        );
    };

    return {
        openEmployeeEdit,
        onChangeDate,
        onChange,
        state,
        onSubmit,
        loading,
        show,
    };
};

export default EditLogic;
