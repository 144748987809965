import i18n from "../../../../languages/i18nextConf";

export const headerResultLabel = false;
export const columnHeaders = () => {
    return [
        {
            name: "id",
            label: i18n.t("clients.table.id"),
            sort: true,
            status: "desc",
        },
        {
            name: "first_name",
            label: i18n.t("clients.table.first_name"),
            sort: true,
            status: "none",
        },
        {
            name: "last_name",
            label: i18n.t("clients.table.last_name"),
            sort: true,
            status: "none",
        },
        {
            name: "country",
            label: i18n.t("clients.table.country"),
            sort: false,
            status: "none",
        },
        {
            name: "email",
            label: i18n.t("clients.table.email"),
            sort: true,
        },
        {
            name: "phone",
            label: i18n.t("clients.table.phone"),
            sort: true,
        },
        {
            name: "total_reservations",
            label: i18n.t("clients.table.reservations"),
            sort: false,
            status: "none",
        },
        {
            name: "total_sum_reservations",
            label: i18n.t("clients.table.amount"),
            sort: false,
        },

        {
            name: "actions",
            label: i18n.t("clients.table.actions"),
            sort: false,
        },
    ];
};

export const tableFilters = {
    // first_name: {
    //     label: "Demo",
    //     filterName: "Demo",
    //     data: [
    //         {
    //             id: 1,
    //             name: "Ivan",
    //         },
    //         {
    //             id: 2,
    //             name: "Dragan",
    //         },
    //         {
    //             id: 3,
    //             name: "Petkan",
    //         },
    //     ],
    // },
};
