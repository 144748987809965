import i18n from "../../../../languages/i18nextConf";

export const headerResultLabel = false;
export const columnHeaders = () => {
    return [
        {
            name: "id",
            label: i18n.t("parkings.table.id"),
            sort: true,
            status: "desc",
        },
        {
            name: "name",
            label: i18n.t("parkings.table.name"),
            sort: false,
        },
        {
            name: "city",
            label: i18n.t("parkings.table.city"),
            sort: true,
        },
        {
            name: "street",
            label: i18n.t("parkings.table.street"),
            sort: false,
        },

        {
            name: "number",
            label: i18n.t("parkings.table.number"),
            sort: false,
        },
        {
            name: "phone",
            label: i18n.t("parkings.table.phone"),
            sort: false,
        },
        {
            name: "lots",
            label: i18n.t("parkings.table.lots"),
            sort: true,
        },

        {
            name: "action",
            label: "Actions",
            label: i18n.t("parkings.table.action"),
            sort: false,
        },
    ];
};

export const tableFilters = {
    // first_name: {
    //     label: "Demo",
    //     filterName: "Demo",
    //     data: [
    //         {
    //             id: 1,
    //             name: "Ivan",
    //         },
    //         {
    //             id: 2,
    //             name: "Dragan",
    //         },
    //         {
    //             id: 3,
    //             name: "Petkan",
    //         },
    //     ],
    // },
};
