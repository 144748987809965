import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";

import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import inviteEmployeeShema from "../../../../_validations/inviteEmployeeShema";

import InviteEmployeeLogic from "./InviteEmployeeLogic";

import st from "./styles.module.scss";

const InvideEmpoyee = () => {
    const { t } = useTranslation();

    const show = useSelector((state) => state.inviteEmployee.show);

    const { state, onChange, onSubmit, showPopUp, loading } = InviteEmployeeLogic();

    return (
        <>
            <div className={"pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img className={st.close} src={ClosePopup} onClick={() => showPopUp(false)} />
                    <h3> {t("employees.inviteForm.title")}</h3>
                    <div className='form-wrapper'>
                        <Formik
                            enableReinitialize
                            initialValues={state}
                            validationSchema={inviteEmployeeShema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValidating, isValid, dirty }) => {
                                return (
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='first_name'
                                                        type='text'
                                                        label={t("employees.inviteForm.first_name")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='last_name'
                                                        type='text'
                                                        label={t("employees.inviteForm.last_name")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row pb-10'>
                                            <div className='col-md-12'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='email'
                                                        type='text'
                                                        label={t("employees.inviteForm.email")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row pb-10'>
                                            <div className='col-md-12'>
                                                <h4 className='mb-3'>
                                                    {t("employees.inviteForm.assign_role")}
                                                </h4>
                                                <div className='radio_box' role='radio-group'>
                                                    <label className='mt-0 mb-3'>
                                                        <Field
                                                            type='radio'
                                                            name='role_id'
                                                            value='2'
                                                        />
                                                        <div className='input_holder'>
                                                            {t("employees.inviteForm.manager")}
                                                            <span></span>
                                                        </div>
                                                    </label>
                                                    <label className='mt-0 mb-3'>
                                                        <Field
                                                            type='radio'
                                                            name='role_id'
                                                            value='3'
                                                        />
                                                        <div className='input_holder'>
                                                            {t("employees.inviteForm.valet")}
                                                            <span></span>
                                                        </div>
                                                    </label>
                                                    {errors.role_id && touched.role_id && (
                                                        <div className='custom-error'>
                                                            {errors.role_id}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='text-end'>
                                            <button
                                                type='button'
                                                className='btn btn-cancel-plain'
                                                onClick={() => showPopUp(false)}
                                            >
                                                {t("employees.inviteForm.cancel")}
                                            </button>
                                            <button
                                                disabled={loading || !(isValid && dirty)}
                                                type='submit'
                                                className='btn btn-submit'
                                            >
                                                {t("employees.inviteForm.submit")}
                                            </button>

                                            <ButtonLoader show={loading} />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvideEmpoyee;
