import axios from "../../configAxios.js";
import { setMessage } from "../../components/AlertMessage/_actions.js";

export const getProfile = (dispatch) => {
    return axios
        .get("employees/my-profile")
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_USER_PROFILE",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
