import * as yup from "yup";
import i18n from "../languages/i18nextConf";

export const personalInfo = yup.object().shape({
    first_name: yup.string().required("Requis!"),
    last_name: yup.string().required("Requis!"),
    email: yup.string().email().required("Requis!"),
    password: yup
        .string()
        .required("Le mot de passe est obligatoire")
        .min(6, "Doit comporter au moins 6 symboles"),
    // .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "validations.password_rule"
    // ),
    phone: yup.string().required("Requis!"),
    city: yup.string().required("Requis!"),
    post_code: yup.string().required("Requis!"),
    street: yup.string().required("Requis!"),
    street_number: yup.string().required("Requis!"),
});

export const carInfo = yup.object().shape({
    flight_number: yup.string().required("Requis!"),
    brand: yup.string().required("Requis!"),
    model: yup.string().required("Requis!"),
    plate: yup.string().required("Requis!"),
    agree: yup.string().required("Requis!"),
});
export const clientCar = yup.object().shape({
    vehicle_id: yup.string().required("Requis!"),
    flight_number: yup.string().required("Requis!"),
});
