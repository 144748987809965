import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import PaymentStripe from "./PaymentStripe";

const StripeWrapper = () => {
    const stripePromise = loadStripe("pk_live_B8HdWAAi4FfaDV3u0pxI3g0b00FEs1qhOS");
    // const stripePromise = loadStripe("pk_test_0ABbdKVbMwdgiWXED81Dh2b500ApvrZZsX");
    return (
        <Elements stripe={stripePromise}>
            <PaymentStripe />
        </Elements>
    );
};

export default StripeWrapper;
