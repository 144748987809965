// vacationDays

const initialState = {
    selectedMonth: "",
    vacations: [],
    birthdaysList: [],
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_MONTH_VACANCIES":
            return {
                ...state,
                selectedMonth: payload,
            };
        case "GET_VACATIONS_CALENDAR":
            return {
                ...state,
                vacations: payload,
            };
        case "GET_EMPLOYEES_BIRTHDAYS_CALENDAR":
            return {
                ...state,
                birthdaysList: payload,
            };

        default:
            return state;
    }
};

export default reducer;
