import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import dispatcher from "../_dispatcher";

import { getCountriesList } from "../_actions";

const Logic = () => {
    const dispatch = useDispatch();
    const complete_first_step = useSelector((state) => state.clientBooking.complete_first_step);
    const complete_second_step = useSelector((state) => state.clientBooking.complete_second_step);
    const enable_second_step = useSelector((state) => state.clientBooking.enable_second_step);
    const enable_third_step = useSelector((state) => state.clientBooking.enable_third_step);
    const active_step = useSelector((state) => state.clientBooking.active_step);
    const aditional_payment = useSelector((state) => state.clientBooking.aditional_payment);

    const isAuth = useSelector((state) => state.clientAuth.token_client);

    useEffect(() => {
        getCountriesList(dispatch);
    }, []);

    const { setBookingScreen } = dispatcher();
    const changeStep = (step) => {
        setBookingScreen("active_step", step);
    };
    const active = Number(active_step.toFixed());

    return {
        enable_second_step,
        enable_third_step,
        active,
        changeStep,
        isAuth,
        aditional_payment,
        complete_first_step,
        complete_second_step,
    };
};

export default Logic;
