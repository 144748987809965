// statisticsReducer

const initialState = {
    statistics: {},
    reservations: 0,
    total_sum: 0,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_DASHBOARD_STATISTICS":
            const { stats, reservations_count, reservations_total_sum } = payload;
            const moderated = Object.keys(stats).map((item) => {
                return { profit: stats[item].profit, id: item, name: stats[item].name };
            });

            return {
                ...state,
                statistics: moderated,
                reservations: reservations_count,
                total_sum: reservations_total_sum,
            };
        default:
            return state;
    }
};

export default reducer;
