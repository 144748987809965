import { useDispatch, useSelector } from "react-redux";

import { checkForPlaces } from "../_actions";

const CalendarToolbarLogic = (date, onView) => {
    const dispatch = useDispatch();

    const goToDayView = () => {
        onView("day");
        checkForPlaces(date, dispatch);
    };
    const goToWeekView = () => {
        onView("week");
        dispatch({
            type: "SET_FREE_PLACES",
            payload: [],
        });
    };
    const goToMonthView = () => {
        onView("month");
        dispatch({
            type: "SET_FREE_PLACES",
            payload: [],
        });
    };

    return { goToDayView, goToWeekView, goToMonthView };
};

export default CalendarToolbarLogic;
