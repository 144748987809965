import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import YouTube from "react-youtube";
import CkeckIcon from "../../../../images/home/ckeck.png";

import Pm_1 from "../../../../images/home/pm_1.png";
import Pm_2 from "../../../../images/home/pm_2.png";
import Pm_3 from "../../../../images/home/pm_3.png";

import PriceCalculator from "../PriceCalculator/index";
import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const { videoOptions, videoId } = Logic();

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className={st.hero_wrapper}>
                        <div className={st.text_holder}>
                            <div className={st.title_wrapper}>
                                <h2> {t("hero.title")} </h2>
                                <h4>{t("hero.secondary_title")} </h4>

                                <PriceCalculator className={st.price_calc} />
                            </div>

                            <div className={st.blue_bg}>
                                <ul>
                                    <li>
                                        <div className={st.check_icon}>
                                            <img src={CkeckIcon} alt='' />
                                        </div>
                                        {t("hero.list_1")}
                                    </li>
                                    <li>
                                        <div className={st.check_icon}>
                                            <img src={CkeckIcon} alt='' />
                                        </div>
                                        {t("hero.list_2")}
                                    </li>
                                    <li>
                                        <div className={st.check_icon}>
                                            <img src={CkeckIcon} alt='' />
                                        </div>
                                        {t("hero.list_3")}
                                    </li>
                                </ul>

                                <div className={st.payment_methods}>
                                    <img src={Pm_1} alt='' />
                                    <img src={Pm_2} alt='' />
                                    <img src={Pm_3} alt='' />

                                    <p> {t("hero.payments")}</p>
                                </div>
                            </div>
                        </div>
                        <div className={st.video_wrapper}>
                            <YouTube videoId={videoId} opts={videoOptions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
