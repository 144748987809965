import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import {
  getSinglePromocode,
  updatePromocode,
  createPromocode,
} from "./_actions";
import { getPromocodesList } from "../List/_actions";

const EditLogic = () => {
  const dispatch = useDispatch();
  const promocode = useSelector((state) => state.singlePromocode.promocode);
  const promocodeId = useSelector((state) => state.singlePromocode.promocodeId);
  const showEdit = useSelector((state) => state.singlePromocode.showEdit);

  const [loading, setLoader] = useState(false);

  const [switchState, setSwitchState] = useState({
    isChecked: false,
  });

  const handleChange = (event) => {
    setSwitchState({ ...state, [event.target.name]: event.target.checked });
  };

  const intialState = {
    id: "",
    code: "",
    from_date: null,
    to_date: null,
    number_of_uses: "",
    value: "",
  };
  const [state, setState] = useState(intialState);
  const [active, toggleActive] = useState(false);
  const [type, toggleType] = useState(false);

  const clearForm = () => {
    setState({ ...state, id: null });
    setTimeout(() => {
      setState(intialState);
    }, 100);
  };

  useEffect(() => {
    if (promocodeId && showEdit) {
      getSinglePromocode(promocodeId, dispatch);
    } else {
      clearForm();
    }
  }, [promocodeId, showEdit]);

  useEffect(() => {
    if (Object.keys(promocode).length !== 0) {
    //   console.log("promocode", state);
      setState(promocode);
      toggleActive(promocode.active === 1 ? true : false);
      setSwitchState(promocode.type === 1 ? {isChecked: true} : {isChecked: false});
    }
  }, [promocode]);

  const openPromocodeEdit = () => {
    dispatch({
      type: "SHOW_EDIT_PROMOCODE_POPUP",
      payload: { id: "", status: false },
    });
  };

  const querySting = useSelector((state) => state.listTableReducer.query);
  const onSubmit = async (data, { setErrors, resetForm }) => {
    if (promocodeId) {
      const from_date = moment(data.from_date).format("YYYY-MM-DD");
      const to_date = moment(data.to_date).format("YYYY-MM-DD");
      updatePromocode(
        { ...data, from_date, to_date, active, type: switchState.isChecked },
        dispatch
      ).then((response = {}) => {
        setLoader(false);
        if (response.status === 204) {
          getPromocodesList(querySting, dispatch);
          dispatch({
            type: "SHOW_EDIT_PROMOCODE_POPUP",
            payload: { id: "", status: false },
          });
          resetForm({ values: "" });
          clearForm();
        }

        if (response.errors) {
          let moderatedErrors = { error: "" };
          for (const property in response.errors) {
            moderatedErrors = {
              ...moderatedErrors,
              [property]: response.errors[property].toString(),
            };
          }

          setErrors(response.errors);
        }
      });
    } else {
      createPromocode({ ...data, active, type: switchState.isChecked }, dispatch).then(
        (response = {}) => {
          setLoader(false);
          if (response.status === 201) {
            getPromocodesList(querySting, dispatch);
            dispatch({
              type: "SHOW_EDIT_PROMOCODE_POPUP",
              payload: { id: "", status: false },
            });
            resetForm({ values: "" });
            clearForm();
          }

          if (response.errors) {
            let moderatedErrors = { error: "" };
            for (const property in response.errors) {
              moderatedErrors = {
                ...moderatedErrors,
                [property]: response.errors[property].toString(),
              };
            }

            setErrors(response.errors);
          }
        }
      );
    }
  };

  return {
    openPromocodeEdit,
    state,
    onSubmit,
    loading,
    promocodeId,
    clearForm,
    active,
    toggleActive,
    type,
    toggleType,
    switchState,
    handleChange
  };
};

export default EditLogic;
