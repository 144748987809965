import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

const CustomEventDay = (event) => {
    const { t } = useTranslation();
    const {
        start,
        type,
        id,
        payment_status,
        departure_valet,
        arrival_valet,
        parking_name,
        vehicle,
        flight_number,
    } = event.event;
    const { brand, model, plate } = vehicle;

    const startTime = moment(start).format("HH:mm -- DD-MM-YYYY");

    return (
        <div className='event-day-view'>
            <div className='id-wrapper'>
                {id}
                {payment_status === "pending" && (
                    <span>
                        <i className='fas fa-exclamation-triangle'></i>
                    </span>
                )}
                {type === "start" && !Boolean(departure_valet) && (
                    <i className='fas fa-user-slash'></i>
                )}
                {type === "end" && !Boolean(arrival_valet) && <i className='fas fa-user-slash'></i>}
            </div>

            <div className='parking'>
                <i className='fas fa-parking'></i> {parking_name}
                <br />
                <i className='fas fa-car me-2'></i>
                {brand} {model} "{plate}"
                <br />
                <i className='fas fa-globe-europe me-2'></i>
                {flight_number ? flight_number : "none"}
            </div>
            <div className='valet-wrapper'>
                {t("reservations.preview.departure_valet")}:{" "}
                <b>
                    {departure_valet
                        ? departure_valet.first_name + " " + departure_valet.last_name
                        : "none"}
                </b>
                <br />
                {t("reservations.preview.arrival_valet")}:{" "}
                <b>
                    {arrival_valet
                        ? arrival_valet.first_name + " " + arrival_valet.last_name
                        : "none"}
                </b>
            </div>
            <div className='date-wrapper'>
                <strong>{startTime}</strong>
                {event.title}
            </div>
        </div>
    );
};

export default CustomEventDay;
