import axios from "../../../configAxiosPublic";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

export const getCountriesList = (dispatch) => {
    return axios
        .get("countries")
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                const data = result.data.data;
                const Switzerland = data.find((item) => item.name === "Switzerland");
                const France = data.find((item) => item.name === "France");
                const Italy = data.find((item) => item.name === "Italy");
                const Germany = data.find((item) => item.name === "Germany");
                const Uk = data.find((item) => item.name === "United Kingdom");

                const RestOfTheWorld = data.filter((item) => {
                    return (
                        item.name !== "Switzerland" &&
                        item.name !== "France" &&
                        item.name !== "Italy" &&
                        item.name !== "Germany" &&
                        item.name !== "United Kingdom"
                    );
                });

                dispatch({
                    type: "SET_COUNTRY_LIST",
                    payload: [Switzerland, France, Italy, Germany, Uk, ...RestOfTheWorld],
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const calcPrice = (data, dispatch) => {
    const { arrival_at, departure_at, promocode } = data;
    console.log(data)
    return axios
        .get(
            "calc-price?departure_at=" + departure_at + ":00" + "&arrival_at=" + arrival_at + ":00" + "&promocode=" + promocode
        )
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const getSingleReservation = (id, dispatch) => {
    return axios
        .get("reservation/" + id)
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_BOOKING_RESERVATION",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const getPricePerDay = (days, dispatch) => {
    return axios
        .get("price/" + days)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const checkForPlaces = (data, dispatch) => {
    const { arrival_at, departure_at } = data;
    return axios
        .get(
            "parking-places?departure_at=" +
                departure_at +
                ":00" +
                "&arrival_at=" +
                arrival_at +
                ":00"
        )
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const getReviews = (dispatch) => {
    return axios
        .get("reviews")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_SITE_REVIEWS",
                    payload: data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const login = (аuth, dispatch) => {
    return axios
        .post("login", { ...аuth })
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "LOGIN_SUCCESS_CLIENT",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const createCar = (data, dispatch) => {
    return axios
        .post("vehicles", data)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const createReservation = (data, dispatch) => {
    const { reservation_id, ...rest } = data;
    return axios
        .post("reservation", rest)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const editReservation = (data, dispatch) => {
    const { reservation_id, ...rest } = data;
    return axios
        .put("reservation/" + reservation_id, rest)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const registerClient = (email, dispatch) => {
    return axios
        .post("register", email)
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "LOGIN_SUCCESS_CLIENT",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
