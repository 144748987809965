import axios from "../../../../configAxios.js";
import { setMessage } from "../../../../components/AlertMessage/_actions.js";

export const getSingleReservation = (id, dispatch) => {
    return axios
        .get("/reservation/" + id + "?parkings=true")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_SINGLE_RESERVATION",
                    payload: data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const updateSingleReservation = (data, dispatch) => {
    const { id } = data;

    return axios
        .put("/reservation/" + id, data)
        .then((result) => {
            const { status, data } = result;

            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const createReservation = (data, dispatch) => {
    return axios
        .post("/reservation", data)
        .then((result) => {
            const { status, data } = result;

            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const setNewStatus = (id, data, dispatch) => {
    return axios
        .put("/reservation-status/" + id, { status: data })
        .then((result) => {
            const { status, data } = result;

            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const setNewStatusWithPayment = (id, data, dispatch) => {
    return axios
        .put("/reservation-status/" + id, { status: data.status, payment_status: data.payment_status })
        .then((result) => {
            const { status, data } = result;

            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const cancelReservation = (id, dispatch) => {
    return axios
        .put("/reservation-cancel/" + id)
        .then((result) => {
            const { status, data } = result;
            if (status === 204) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const uploadCarImage = (files, reservation_id, dispatch) => {
    const formData = new FormData();

    files.map((item) => {
        return formData.append("file[]", item);
    });

    formData.append("reservation_id", reservation_id);

    return axios
        .post("reservation-vehicles", formData)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const deleteCarImage = (uuid, dispatch) => {
    return axios
        .delete("reservation-vehicles/" + uuid)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const getAllVallets = (dispatch) => {
    return axios
        .get("/employees?page[size]=100&filter[status]=active")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_ALL_VALLETS",
                    payload: data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const getAllClients = (dispatch) => {
    return axios
        .get("/clients-all?include=vehicles")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_ALL_CLIENTS",
                    payload: data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
