import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import Disptacher from "../_dispatcher";

const Logic = () => {
    // const reviews = useSelector((state) => state.homePageReducer.reviews);
    const { resetBookingForm } = Disptacher();

    const reviews = [
        {
            rating: "5",
            profile_photo_url:
                "https://lh3.googleusercontent.com/a-/AFdZucorW3-cD0FaPxmw_hcvNEdpl1iZKxD2weRGAtSQPw=s40-c-c0x00000000-cc-rp-mo-br100",
            author_name: "Curchod Jean",
            text: "Partenaire sérieux et fiable : s'informe, donne tous les renseignements nécessaires, met en place un suivi qui sécurise et qui assure que tout se déroule à la perfection du début à la fin - avec en prime une amabilité qui ne gâche rien. Le site est aisé à trouver, bien situé à vraiment cinq minutes de l'aéroport.  Nous recommandons cette entreprise et le cas échéant aurons à nouveau recours à ses services.  ",
        },
        {
            rating: "5",
            profile_photo_url:
                "https://lh3.googleusercontent.com/a-/AFdZucqXICGq4RKY_hn8OrN_K0D5jIGeHs5XNJogUs9hmW0=s40-c-c0x00000000-cc-rp-mo-ba3-br100",
            author_name: "Olivier Wasem",
            text: "L'équipe Park & Fly Geneva est exemplaire et je ne peux que recommander leurs services. Loin de toute publicité mensongère, tout au contraire, ils offrent de l'excellence à un prix plus qu'abordable. Il y a peu de temps alors que je leur avais confié mon véhicule, comme j'ai ensuite compris d'autres également, a souffert d'un acte de vandalisme et j'ai reçu une irréprochable et bienveillante assistance de leur part, ce qui m'a permis sans difficultés de faire face à cette regrettable situation. ",
        },
        {
            rating: "5",
            profile_photo_url:
                "https://lh3.googleusercontent.com/a-/AFdZucqe-5U_JkmUZ9YgUF_pbMWSp_w88xFYoiNrfXkqfg=s40-c-c0x00000000-cc-rp-mo-br100",
            author_name: "Mahboube Rahnama",
            text: "Excellent, le meilleur endroit pour laisser ton véhicule tranquille. Nous avons laissé notre voiture pendant 3 semaines chez Par&Fly et tout s'est passé parfaitement. ",
        },
        {
            rating: "5",
            profile_photo_url:
                "https://lh3.googleusercontent.com/a-/AFdZucqMNA6v7zM1B2Y9R1K9IpTalkdCeC8AZmp_Oh1K=s40-c-c0x00000000-cc-rp-mo-br100",
            author_name: "Dinh Hien Nguyen",
            text: "Très très bon service ainsi que le personnel. J’ai retrouvé ma voiture avec aucun défaut! Rien à dire si ce n’est que parfait!",
        },

        {
            rating: "5",
            profile_photo_url:
                "https://lh3.googleusercontent.com/a-/AFdZuco1YQYMOylweHsGsF5P1fYlgyegZxiUm7UbADsW4g=s40-c-c0x00000000-cc-rp-mo-br100",
            author_name: "Gregory Gabriel",
            text: "Excellent service à chaque fois que je l'utilise, tant au niveau de la réservation (très simple par internet), que de l'assistance client (rapidité de réponse par email sur demande spéciale de ma part), que de la rapidité à rapporter la voiture une fois l'appel réalisé auprès du voiturier.  ",
        },
    ];
    const reveiwSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
        slidesToScroll: 1,
    };

    return { reviews, reveiwSettings, resetBookingForm };
};

export default Logic;
