import { useState } from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { login } from "../_actions";

const LoginLogic = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    const initialValues = {
        email: "",
        password: "",
    };
    const [loading, setLoader] = useState(false);
    const [state, setState] = useState(initialValues);

    const [passType, setPassType] = useState(false);
    const togglePassType = () => setPassType(!passType);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        login(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 200) {
                togglePopUp("showLogin", false);
                dispatch({
                    type: "OPEN_POP_CLIENT",
                    payload: { name: "showProfile", value: true },
                });
            }

            if (response.data.status_code === 401) {
                setErrors({ email: t("client_auth.unauthorized") });
            }
        });
    };

    return { togglePopUp, onSubmit, loading, passType, togglePassType, state };
};

export default LoginLogic;
