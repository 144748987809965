import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";
import { useSelector, useDispatch } from "react-redux";

import ClosePopup from "../../../images/icons/close-popup-icon.svg";
import st from "./styles.module.scss";

const ForgotPassword = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const show = useSelector((state) => state.clientAuth.showCheckEmail);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    return (
        <div className={st.info_popup + " pop  client_forms " + (show ? "show" : "")}>
            <div className={st.inner + " inner"}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => togglePopUp("showCheckEmail", false)}
                />
                <div className={st.form_wrapper}>
                    <h3>Check your email</h3>
                    <p className={st.introText}>
                        Enter the e-mail addres associated with your account and we’ll send you a
                        link to reset your password
                    </p>
                    <div className={st.btn_wrapper}>
                        <button
                            type='button'
                            onClick={() => {
                                togglePopUp("showCheckEmail", false);
                            }}
                            className='green_btn_h'
                        >
                            Continue
                        </button>
                    </div>
                    <p className={st.small_text}>
                        Did not recieve an email? Check your spam filter,{" "}
                        <span
                            onClick={() => {
                                togglePopUp("showCheckEmail", false);
                                togglePopUp("showForgotPassword", true);
                            }}
                        >
                            or resent the email
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
