import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../languages/i18nextConf";

import { NavLink } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Logo from "../../images/home/logo-footer.png";

import st from "./styles.module.scss";

import { DashboardRoutes } from "../../routing/DashboardRoutes";
import SideBarLogic from "./SideBarLogic";

const SideBar = () => {
    const { t } = useTranslation();
    const {
        language,
        changeLanguage,
        toggleEmployees,
        showEmpoyees,
        showClients,
        toggleShowClients,
        tempExit,
        showNav,
        toggleShowNav,
    } = SideBarLogic();
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    const role = useSelector((state) => state.dashboardReducer.role);

    return (
        <>
            <div className={st.sidebar}>
                <div className={st.logo_wrapper}>
                    <img className={st.logo} src={Logo} alt='' />
                    <div>
                        <FormControl
                            variant='outlined'
                            className={st.select + " full-width select-input"}
                        >
                            <Select value={language} onChange={changeLanguage}>
                                <MenuItem value='fr'>Fr</MenuItem>
                                <MenuItem value='en'>En</MenuItem>
                                {/* <MenuItem value='de'>De</MenuItem> */}
                            </Select>
                        </FormControl>
                        <i
                            className={"fas fa-bars " + st.mobile_icon}
                            onClick={() => toggleShowNav(true)}
                        ></i>
                    </div>
                </div>
                <div className={st.main_nav_wrapper + " " + (showNav ? st.show : "")}>
                    <div className={st.close} onClick={() => toggleShowNav(false)}>
                        <i className='far fa-times-circle'></i>
                    </div>
                    <ul className={st.main_nav}>
                        {userPermitions["view_any_dashboard"] && (
                            <li onClick={() => toggleShowNav(false)}>
                                <NavLink
                                    activeClassName='active'
                                    to={DashboardRoutes["Statistics"].path}
                                >
                                    {t("sidebar.Statistics")}
                                </NavLink>
                            </li>
                        )}
                        {(userPermitions["view_any_employee"] ||
                            userPermitions["view_any_vacation"]) && (
                            <li>
                                <span
                                    className={
                                        st.dropDown_link + " " + (showEmpoyees ? st.active : "")
                                    }
                                    onClick={() => toggleEmployees(!showEmpoyees)}
                                >
                                    {t("sidebar.Employees")}
                                    <i
                                        className={
                                            "fas fa-chevron-down " + (showEmpoyees ? st.show : "")
                                        }
                                    ></i>
                                </span>
                                <ul
                                    className={
                                        st.dropDown_menu + " " + (showEmpoyees ? st.show : "")
                                    }
                                >
                                    {userPermitions["view_any_vacation"] && (
                                        <li onClick={() => toggleShowNav(false)}>
                                            <NavLink
                                                activeClassName='active'
                                                to={DashboardRoutes["VacationsDays"].path}
                                            >
                                                {t("sidebar.VacationsDays")}
                                            </NavLink>
                                        </li>
                                    )}
                                    {role !== 3 && Boolean(userPermitions["view_any_employee"]) && (
                                        <li onClick={() => toggleShowNav(false)}>
                                            <NavLink
                                                activeClassName='active'
                                                to={DashboardRoutes["Employees"].path}
                                            >
                                                {t("sidebar.Employees")}
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {(userPermitions["view_any_reservation"] ||
                            userPermitions["view_any_client"]) && (
                            <li>
                                <span
                                    className={
                                        st.dropDown_link + " " + (showClients ? st.active : "")
                                    }
                                    onClick={() => toggleShowClients(!showClients)}
                                >
                                    {t("sidebar.Clients")}

                                    <i
                                        className={
                                            "fas fa-chevron-down " + (showClients ? st.show : "")
                                        }
                                    ></i>
                                </span>
                                <ul
                                    className={
                                        st.dropDown_menu + " " + (showClients ? st.show : "")
                                    }
                                >
                                    {userPermitions["view_any_reservation"] && (
                                        <li onClick={() => toggleShowNav(false)}>
                                            <NavLink
                                                activeClassName='active'
                                                to={DashboardRoutes["Reservations"].path}
                                            >
                                                {t("sidebar.Reservations")}
                                            </NavLink>
                                        </li>
                                    )}
                                    {userPermitions["view_any_client"] && (
                                        <li onClick={() => toggleShowNav(false)}>
                                            <NavLink
                                                activeClassName='active'
                                                to={DashboardRoutes["Clients"].path}
                                            >
                                                {t("sidebar.AllClients")}
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        <li onClick={() => toggleShowNav(false)}>
                            <NavLink activeClassName='active' to={DashboardRoutes["Calendar"].path}>
                                {t("sidebar.Calendar")}
                            </NavLink>
                        </li>

                        {role !== 3 && Boolean(userPermitions["view_any_parking"]) && (
                            <li onClick={() => toggleShowNav(false)}>
                                <NavLink
                                    activeClassName='active'
                                    to={DashboardRoutes["Parkings"].path}
                                >
                                    {t("sidebar.Parkings")}
                                </NavLink>
                            </li>
                        )}
                        {userPermitions["view_any_promo_code"] && (
                            <li onClick={() => toggleShowNav(false)}>
                                <NavLink
                                    activeClassName='active'
                                    to={DashboardRoutes["Promocodes"].path}
                                >
                                    {t("sidebar.Promocodes")}
                                </NavLink>
                            </li>
                        )}
                        {userPermitions["view_any_price"] && (
                            <li onClick={() => toggleShowNav(false)}>
                                <NavLink
                                    activeClassName='active'
                                    to={DashboardRoutes["Prices"].path}
                                >
                                    {t("sidebar.Prices")}
                                </NavLink>
                            </li>
                        )}
                        <li onClick={() => toggleShowNav(false)}>
                            <a onClick={tempExit} style={{ color: "#fff" }}>
                                {t("sidebar.LogOut")}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default SideBar;
