import Employees from "../pages/Dashboard/Employees/List/List";
import VacationsDays from "../pages/Dashboard/VacationsDays/Calendar/Calendar";
import Clients from "../pages/Dashboard/Clients/List/List";
import ClientSingle from "../pages/Dashboard/Clients/Single/Single";
import Reservations from "../pages/Dashboard/Reservations/List/List";
import Parkings from "../pages/Dashboard/Parkings/List/List";
import Promocodes from "../pages/Dashboard/Promocodes/List/List";
import Prices from "../pages/Dashboard/Prices/Prices";
import Calendar from "../pages/Dashboard/Calendar/Calendar";
import Statistics from "../pages/Dashboard/Statistics/Statistics";

const mainDirUrl = "/dashboard/";
export const DashboardRoutes = {
    Statistics: {
        component: Statistics,
        path: mainDirUrl + "statistics",
        name: "Dashboard",
        exact: true,
        inNav: true,
        permition: "view_any_dashboard",
    },
    Employees: {
        component: Employees,
        path: mainDirUrl + "employees",
        name: "Employees",
        exact: true,
        inNav: true,
        permition: "view_any_employee",
    },
    VacationsDays: {
        component: VacationsDays,
        path: mainDirUrl + "vacations",
        name: "Vacations Calendar",
        exact: true,
        inNav: true,
        permition: "view_any_vacation",
    },
    Clients: {
        component: Clients,
        path: mainDirUrl + "clients",
        name: "All Clients",
        exact: true,
        inNav: true,
        permition: "view_any_client",
    },
    ClientSingle: {
        component: ClientSingle,
        path: mainDirUrl + "clients/:_clientId",
        name: "All Clients",
        exact: true,
        inNav: false,
        permition: "view_client",
    },
    Reservations: {
        component: Reservations,
        path: mainDirUrl + "reservations",
        name: "Reservations",
        exact: true,
        inNav: true,
        permition: "view_any_reservation",
    },
    Parkings: {
        component: Parkings,
        path: mainDirUrl + "parkings",
        name: "Parkings",
        exact: true,
        inNav: true,
        permition: "view_any_parking",
    },
    Promocodes: {
        component: Promocodes,
        path: mainDirUrl + "promocodes",
        name: "Promocodes",
        exact: true,
        inNav: true,
        permition: "view_any_promo_code",
    },
    Prices: {
        component: Prices,
        path: mainDirUrl + "prices",
        name: "Prices",
        exact: true,
        inNav: true,
        permition: "view_any_price",
    },
    Calendar: {
        component: Calendar,
        path: mainDirUrl + "calendar",
        name: "Calendar",
        exact: true,
        inNav: true,
        permition: "view_reservation",
    },
};
