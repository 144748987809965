import axios from "../../../configAxiosPublic";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

export const login = (аuth, dispatch) => {
    return axios
        .post("login", { ...аuth })
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "LOGIN_SUCCESS_CLIENT",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            return error.response.data;
        });
};

export const forgotPassword = (email, dispatch) => {
    return axios
        .post("password", email)
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const resetPassword = (data, dispatch) => {
    return axios
        .put("password/reset", data)
        .then((result) => {
            const { status } = result;
            if (status === 204) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const logout = (dispatch) => {
    dispatch({
        type: "LOGOUT_CLIENT",
        payload: "",
    });
    dispatch({
        type: "RESET_BOOKING_DATA",
        payload: "",
    });
};
