import React from "react";
import { useSelector } from "react-redux";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import CustomTooltip from "./CustomTooltip";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { Link } from "react-router-dom";

import st from "./styles.module.scss";

import StatisticsLogic from "./StatisticsLogic";
const Statistics = () => {
    const { t } = useTranslation();

    const { year, month, monthNames, changeMonth } = StatisticsLogic();
    const reservations = useSelector((state) => state.statisticsReducer.reservations);
    const total_sum = useSelector((state) => state.statisticsReducer.total_sum);
    const statistics = useSelector((state) => state.statisticsReducer.statistics);

    return (
        <>
            <div className='page-header top-section-list'>
                <h2 className='title'> {t("statistics.statistics")} </h2>
            </div>
            <div className='container-fluid wrapper'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className={st.info_sections + " bg"}>
                            <div>
                                <h4>
                                    {t("statistics.profit_title")} - {monthNames[month]} {year}
                                </h4>
                                <p className={st.number}>
                                    {total_sum}
                                    <span>CHF</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className={st.info_sections + " bg"}>
                            <div>
                                <h4>
                                    {t("statistics.reservations_title")} - {monthNames[month]}{" "}
                                    {year}
                                </h4>
                                <p className={st.number}>{reservations}</p>
                            </div>

                            <Link to='/dashboard/reservations' className='btn btn-submit'>
                                {t("statistics.reservations_btn")}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <div className={st.info_sections + " bg"}>
                            <div className='row'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4 text-center'>
                                    <div className='noselect'>
                                        <div className={st.year}>{year}</div>
                                        <div className={st.month}>
                                            <i
                                                onClick={() => changeMonth("decrease")}
                                                className='fas fa-angle-left'
                                            ></i>
                                            <p> {monthNames[month]}</p>

                                            <i
                                                onClick={() => changeMonth("increase")}
                                                className='fas fa-angle-right'
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className={st.chart_wrapper}>
                                        <div className={st.chart_wrapper_inner}>
                                            <ResponsiveContainer width='100%' height='90%'>
                                                <LineChart
                                                    width={500}
                                                    height={300}
                                                    data={statistics}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray='3 3' />

                                                    <XAxis dataKey='id' />
                                                    <YAxis />
                                                    {/* <Tooltip /> */}
                                                    <Tooltip
                                                        content={
                                                            <CustomTooltip
                                                                month={month}
                                                                year={year}
                                                            />
                                                        }
                                                    />
                                                    {/* <Legend /> */}
                                                    <Line
                                                        type='monotone'
                                                        dataKey='profit'
                                                        stroke='#8884d8'
                                                        activeDot={{ r: 8 }}
                                                    />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Statistics;
