import { useState, useEffect } from "react";
import i18n from "../../../../languages/i18nextConf";

import { useSelector, useDispatch } from "react-redux";

import dispatcher from "../_dispatcher";
import { registerClient } from "../_actions";

const RegisterUserLogic = () => {
    const { setBookingScreen } = dispatcher();
    const dispatch = useDispatch();

    const countriesList = useSelector((state) => state.clientBooking.countriesList) || [];

    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        password: "",
        country_id: 214,
        post_code: "",
        city: "",
        street: "",
        street_number: "",
        phone: "",
    });

    const [loading, setLoader] = useState(false);
    const [phoneCode, setPhoneCode] = useState("41");

    const [country, setCountry] = useState({
        id: 214,
        name: "Switzerland",
    });

    const [disableSumbit, setDisableSubmit] = useState(true);
    useEffect(() => {
        const { company, country_id, ...forValidation } = state;

        const isValid = Object.values(forValidation).every((x) => x.toString().length > 0);

        if (isValid) {
            setDisableSubmit(false);
        }
    }, [state]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const handleCountryChange = (event, value, reason, name) => {
        if (value) {
            const selected = countriesList.filter((item) => item.id == value.id)[0];
            setState({
                ...state,
                country_id: value.id,
            });
            setCountry({
                id: value.id,
                name: selected.name,
            });
            setPhoneCode(selected.phonecode);
        } else {
            setState({
                ...state,
                country_id: "",
            });

            setCountry({
                id: "",
                name: "",
            });
            setPhoneCode("00");
        }
    };

    const [passType, setPassType] = useState(false);
    const togglePassType = () => setPassType(!passType);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        setState(data);

        const { phone, password } = data;

        const moderated = {
            ...data,
            password_confirmation: password,
            phone: "+" + phoneCode + parseInt(phone, 10),
            lang: i18n.language,
        };
        registerClient(moderated, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 200) {
                setBookingScreen("active_step", 2.9);
            }
            if (response.errors) {
                setErrors(response.errors);
            }
        });
    };

    return {
        state,
        passType,
        togglePassType,
        onSubmit,
        setBookingScreen,

        loading,
        phoneCode,
        handleCountryChange,
        countriesList,
        country,
        disableSumbit,
        onChange,
    };
};

export default RegisterUserLogic;
