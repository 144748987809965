import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import PreviewLogic from "./PreviewLogic";

import st from "./styles.module.scss";

const Preview = () => {
    const { t } = useTranslation();

    const show = useSelector((state) => state.singleEmployee.showPreview);
    const employee = useSelector((state) => state.singleEmployee.employee);

    const { openEmployeePreview, deleteEmployee, openEmployeeEdit } = PreviewLogic();

    const {
        id,
        first_name,
        last_name,
        email,
        insurance_number,
        phone,
        birth_date,
        city,
        district,
        post_code,
        street,
        reservations,
        additional_hours,
        roles = [],
    } = employee;
    const { name: roleName } = roles[0] || {};
    return (
        <>
            <div className={"pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner pb-5"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => openEmployeePreview(false)}
                    />
                    <h3>
                        {first_name} {last_name} - {id}
                    </h3>
                    <div className={st.summary}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h3 className={st.sub_title}>
                                    {t("employees.preview.info_title")}
                                </h3>
                                <table className={st.personal_table}>
                                    <tbody>
                                        <tr>
                                            <td> {t("employees.preview.name")} </td>
                                            <td>
                                                {first_name} {last_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> {t("employees.preview.email")}</td>
                                            <td>{email ? email : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("employees.preview.insurance")} </td>
                                            <td>{insurance_number ? insurance_number : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("employees.preview.phone")} </td>
                                            <td>{phone ? phone : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("employees.preview.birghtday")} </td>
                                            <td>{birth_date ? birth_date : "-"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-md-6'>
                                <h3 className={st.sub_title}>
                                    {t("employees.preview.adress_title")}
                                </h3>
                                <table className={st.personal_table}>
                                    <tbody>
                                        <tr>
                                            <td>{t("employees.preview.city")}</td>
                                            <td>{city ? city : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("employees.preview.district")}</td>
                                            <td>{district ? district : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("employees.preview.zip")}</td>
                                            <td>{post_code ? post_code : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("employees.preview.street")}</td>
                                            <td>{street ? street : "-"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <table className={st.personal_table}>
                                        <tbody>
                                            <tr>
                                                <td>{t("employees.preview.roleName")} </td>
                                                <td>{roleName ? roleName : "none"}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("employees.preview.reservations")} </td>
                                                <td>{reservations ? reservations : "0"}</td>
                                            </tr>

                                            <tr>
                                                <td>{t("employees.preview.hours")} </td>
                                                <td>
                                                    {additional_hours ? additional_hours : "0"}
                                                    {/* <i className='ms-2 fas fa-pen'></i> */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className={st.buttons_wrapper}>
                                        <button
                                            onClick={() => openEmployeeEdit(id)}
                                            type='button'
                                            className={st.btn + " btn btn-submit"}
                                        >
                                            Edit Information
                                        </button>
                                        <button
                                            onClick={() =>
                                                deleteEmployee(id, first_name + " " + last_name)
                                            }
                                            type='button'
                                            className={st.btn + " btn btn-danger"}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preview;
