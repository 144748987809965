import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField, Select } from "formik-material-ui";
import { MenuItem, FormControl, FormHelperText } from "@material-ui/core";

import ClosePopup from "../../../../images/icons/close-popup-icon.svg";
import st from "../styles.module.scss";

import EditReservationLogic from "./EditReservationLogic";

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import frLocale from "moment/locale/fr";
import enLocale from "moment/locale/en-gb";

const EditReservation = () => {
    const { t } = useTranslation();

    const {
        price,
        state,
        setSelectedReservation,
        togglePopUp,
        onSubmit,
        loading,
        toggleTimePicker,
        vehicles,
        onChange,
        setState,
        show,
    } = EditReservationLogic();

    // useEffect(() => {
    //     moment.locale("en");
    // }, []);
    useEffect(() => {
        // moment.locale("en-gb", enLocale);
        // moment.updateLocale("en-gb", {
        //     week: {
        //         dow: 1,
        //     },
        // });
        moment.locale("fr", frLocale);
        moment.updateLocale("fr", {
            week: {
                dow: 1,
            },
        });
    }, []);
    const handleWeekDays = (day) => {
        day._locale._weekdaysMin = ["Su", "Lu", "Ma", "Me", "Je", "Ve", "Sa"];
        return day.format("D");
    };

    const { departure_at, arrival_at } = state;
    const [focusedInput, setFocusetInput] = useState(null);
    return (
        <>
            <div
                className={
                    st.edit_reservation_popup +
                    " " +
                    st.info_popup +
                    " pop  " +
                    (show ? "show" : "")
                }
            >
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => {
                            togglePopUp("showEditReservation", false);
                            togglePopUp("showProfile", true);
                            setSelectedReservation({});
                        }}
                    />
                    <div className={st.form_wrapper}>
                        <h3>{t("client_profile.reservations.edit_title")}</h3>
                        <Formik enableReinitialize initialValues={state} onSubmit={onSubmit}>
                            {({ errors, touched, isValidating, isValid, dirty }) => {
                                return (
                                    <Form className='client_forms'>
                                        <div className='row'>
                                            <div className='col-md-12 '>
                                                <div
                                                    className='range_wrapper_d'
                                                    labelinput1={t(
                                                        "client_booking.side_bar.depart"
                                                    )}
                                                    labelinput2={t(
                                                        "client_booking.side_bar.arival"
                                                    )}
                                                >
                                                    <DateRangePicker
                                                        renderDayContents={handleWeekDays}
                                                        enableOutsideDays={true}
                                                        displayFormat='DD/MM/YYYY'
                                                        startDate={departure_at}
                                                        endDate={arrival_at}
                                                        startDateId='startDateId'
                                                        endDateId='endDateId'
                                                        onDatesChange={({ startDate, endDate }) =>
                                                            setState({
                                                                ...state,
                                                                departure_at: startDate,
                                                                arrival_at: endDate,
                                                            })
                                                        }
                                                        customInputIcon={
                                                            <i className='far fa-calendar-alt'></i>
                                                        }
                                                        customArrowIcon={
                                                            <i className='far fa-calendar-alt'></i>
                                                        }
                                                        orientation={
                                                            window.innerWidth < 767
                                                                ? "vertical"
                                                                : "horizontal"
                                                        }
                                                        withPortal
                                                        readOnly
                                                        focusedInput={focusedInput}
                                                        onFocusChange={(focusedInput) =>
                                                            setFocusetInput(focusedInput)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div
                                                    className='input_withicon_Label'
                                                    onClick={() =>
                                                        toggleTimePicker("departure_at_time")
                                                    }
                                                >
                                                    <Field
                                                        disabled
                                                        name='departure_at_time'
                                                        type='text'
                                                        component={TextField}
                                                        className='input_label'
                                                        label={t(
                                                            "client_profile.reservations.when_departure"
                                                        )}
                                                    ></Field>
                                                    <i className='far fa-clock'></i>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div
                                                    className='input_withicon_Label'
                                                    onClick={() =>
                                                        toggleTimePicker("arrival_at_time")
                                                    }
                                                >
                                                    <Field
                                                        disabled
                                                        name='arrival_at_time'
                                                        type='text'
                                                        className='input_label'
                                                        label={t(
                                                            "client_profile.reservations.when_arrival"
                                                        )}
                                                        component={TextField}
                                                    ></Field>

                                                    <i className='far fa-clock'></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                {Object.keys(price).length !== 0 && (
                                                    <div className={st.cost_wrapper}>
                                                        <p className={st.small}>
                                                            {t(
                                                                "client_profile.reservations.price_label"
                                                            )}
                                                        </p>
                                                        <div className={st.number}>
                                                            {price.price}
                                                            <span>CHF</span>
                                                        </div>
                                                        <p className={st.bold}>
                                                            {t("client_profile.reservations.for")}{" "}
                                                            {price.real_days}{" "}
                                                            {t(
                                                                "client_profile.reservations.days_stay"
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-md-6'>
                                                <Field
                                                    name='flight_number'
                                                    type='text'
                                                    className='input_label'
                                                    label={t("client_booking.step_3.flight_number")}
                                                    component={TextField}
                                                ></Field>
                                                <label className='my_label'>
                                                    select from your Vehicles
                                                </label>
                                                <FormControl
                                                    className='input_label_select'
                                                    variant='outlined'
                                                    error={
                                                        touched["vehicle_id"] &&
                                                        !!errors["vehicle_id"]
                                                    }
                                                >
                                                    <Field
                                                        component={Select}
                                                        type='text'
                                                        name='vehicle_id'
                                                        className=''
                                                        label={t(
                                                            "client_profile.reservations.select_car"
                                                        )}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                        onChange={onChange}
                                                    >
                                                        {vehicles.map((item) => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.brand +
                                                                        " " +
                                                                        item.model +
                                                                        " " +
                                                                        item.plate}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Field>
                                                    {errors.vehicle_id && (
                                                        <FormHelperText>
                                                            {errors.vehicle_id}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                                <p
                                                    className={st.add_car}
                                                    onClick={() => {
                                                        togglePopUp("showEditCar", true);
                                                    }}
                                                >
                                                    <i className='fas fa-plus'></i>

                                                    {t("client_profile.reservations.add_car")}
                                                </p>
                                            </div>
                                        </div>
                                        <button
                                            disabled={loading}
                                            type='submit'
                                            className='green_btn_h'
                                        >
                                            {t("client_profile.reservations.edit")}
                                        </button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditReservation;
