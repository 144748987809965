import { useState } from "react";
import { useDispatch } from "react-redux";

import { forgotPassword } from "../_actions";

const ForgotPasswordLogic = () => {
    const dispatch = useDispatch();

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    const [loading, setLoader] = useState(false);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        forgotPassword(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 200) {
                togglePopUp("showCheckEmail", true);
            }

            if (response.errors) {
                togglePopUp("showErrorEmail", true);
                setErrors(response.errors);
            }
        });
    };

    return { togglePopUp, onSubmit, loading };
};

export default ForgotPasswordLogic;
