import axios from "../../../../configAxios.js";
import { setMessage } from "../../../../components/AlertMessage/_actions.js";

export const getSingleEmployee = (id, dispatch) => {
    return axios
        .get("/employees/" + id + "?include=roles")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_SINGLE_EMPOYEE",
                    payload: data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const updateSingleEmployee = (data, dispatch) => {
    const { id } = data;
    return axios
        .put("/employees/" + id, data)
        .then((result) => {
            const { status, data } = result;

            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const deleteSingleEmpoloyee = (id, dispatch) => {
    return axios
        .delete("/employees/" + id)
        .then((result) => {
            const { status, data } = result;
            if (status === 204) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
