import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSingleEmployee, deleteSingleEmpoloyee } from "./_actions";
import { getEmployeesList } from "../List/_actions";

const PreviewLogic = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const employeeId = useSelector((state) => state.singleEmployee.employeeId);
    const showPreview = useSelector((state) => state.singleEmployee.showPreview);

    const confirmDelete = useSelector((state) => state.confirmDelete);
    const querySting = useSelector((state) => state.listTableReducer.query);

    useEffect(() => {
        const { location, confirm, id } = confirmDelete;
        if (confirm && location === "employees_preview") {
            deleteSingleEmpoloyee(id, dispatch).then((result) => {
                dispatch({
                    type: "SET_DELETE_CONFIRM",
                    payload: {
                        ...confirmDelete,
                        confirm: false,
                    },
                });
                getEmployeesList(querySting, dispatch);
            });
        }
    }, [confirmDelete]);

    const deleteEmployee = (id, fullName) => {
        let text =
            t("clients.delete.question_1") +
            " “" +
            fullName +
            "” " +
            t("clients.delete.question_2");
        dispatch({
            type: "SET_DELETE_CONFIRM",
            payload: {
                show: true,
                text: text,
                location: "employees_preview",
                id: id,
            },
        });
        dispatch({
            type: "SHOW_PREVIEW_EMPLOYEE_POPUP",
            payload: false,
        });
    };

    useEffect(() => {
        if (employeeId && showPreview) {
            getSingleEmployee(employeeId, dispatch);
        }
    }, [employeeId, showPreview]);

    const openEmployeePreview = (data) => {
        dispatch({
            type: "SHOW_PREVIEW_EMPLOYEE_POPUP",
            payload: { id: "", status: data },
        });
    };

    const openEmployeeEdit = (id) => {
        dispatch({
            type: "SHOW_EDIT_EMPLOYEE_POPUP",
            payload: { id, status: true },
        });
        dispatch({
            type: "SHOW_PREVIEW_EMPLOYEE_POPUP",
            payload: { id: "", status: false },
        });
    };

    return { openEmployeePreview, deleteEmployee, openEmployeeEdit };
};

export default PreviewLogic;
