// singleClient

const initialState = {
    tab: 1,
    client: {},
    vehicles: [],
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    const { roles = [] } = payload || {};
    switch (type) {
        case "SET_CLIENT_TAB":
            return {
                ...state,
                tab: payload,
            };
        case "GET_SINGLE_CLIENT":
            return {
                ...state,
                client: payload,
            };
        case "GET_SINGLE_CLIENT_VECHICLES":
            return {
                ...state,
                vehicles: payload,
            };
        default:
            return state;
    }
};

export default reducer;
