import Disptacher from "../_dispatcher";

const Logic = () => {
    const { resetBookingForm } = Disptacher();
    const videoId = "EcQvt_vZMAQ";
    const videoOptions = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            loop: 1,
            controls: 0,
            mute: 1,
            playlist: videoId,
        },
    };

    return {
        videoOptions,
        videoId,
        resetBookingForm,
    };
};

export default Logic;
