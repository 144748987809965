// singleParking

const initialState = {
    showPreview: false,
    parkingId: "",
    showEdit: false,
    parking: {},
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    const { id, status } = payload || {};
    switch (type) {
        case "SHOW_PREVIEW_PARKING_POPUP":
            return {
                ...state,
                showPreview: status,
                parkingId: id,
            };
        case "SHOW_EDIT_PARKING_POPUP":
            return {
                ...state,
                showEdit: status,
                parkingId: id,
            };
        case "GET_SINGLE_PARKING":
            return {
                ...state,
                parking: payload,
            };
        default:
            return state;
    }
};

export default reducer;
