import { useState } from "react";

import { faqList } from "./faqList";

const Logic = () => {
    const [faq, setFaq] = useState(faqList);
    const toggleFaq = (el) => {
        const toggle = faq.map((item) => {
            return el.id === item.id
                ? {
                      ...item,
                      open: !item.open,
                  }
                : {
                      ...item,
                      open: false,
                  };
        });
        setFaq(toggle);
    };

    return { faq, toggleFaq };
};

export default Logic;
