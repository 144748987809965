import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { columnHeaders, tableFilters, headerResultLabel } from "./list_helper";

import { getReservationsList } from "./_actions";

const ListLogic = () => {
    const dispatch = useDispatch();

    const querySting = useSelector((state) => state.listTableReducer.query);
    const list = useSelector((state) => state.reservationsListClient.items);

    const [componentMounted, setComponentMounted] = useState(false);
    useEffect(() => {
        setComponentMounted(true);
    }, []);

    useEffect(() => {
        if (querySting && componentMounted) {
            getReservationsList(querySting, dispatch);
        }
    }, [componentMounted, querySting]);

    useEffect(() => {
        const column = columnHeaders();
        dispatch({
            type: "SET_TABLE_FILTERS_SORT",
            payload: { column, tableFilters, headerResultLabel },
        });
    }, []);

    const openReservationPreview = (id) => {
        dispatch({
            type: "SHOW_PREVIEW_RESERVATION_POPUP",
            payload: { id, status: true },
        });
    };

    const openReservationEdit = (id = null) => {
        dispatch({
            type: "SHOW_EDIT_RESERVATION_POPUP",
            payload: { id, client_id: null, status: true },
        });
    };

    return { list, openReservationEdit, openReservationPreview };
};

export default ListLogic;
