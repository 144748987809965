import axios from "../../../configAxios.js";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

import moment from "moment-timezone";

export const getReservationsCalendar = (month, dispatch) => {
    // dispatch({
    //     type: "GET_RESERVATIONS_CALENDAR",
    //     payload: [
    //         {
    //             id: 1,
    //             from_date: "09.09.2021",
    //             to_date: "12.09.2021",
    //             employee_first_name: "Ivan",
    //             employee_last_name: "Ivanov",
    //         },
    //     ],
    // });

    const startDate = month;
    const year = moment(month, "DD-MM-YYYY").format("YYYY");
    const exactMonth = moment(month, "DD-MM-YYYY").format("MM");
    const lastDayOfMonth = new Date(year, exactMonth, 0).getDate();
    const endDate = lastDayOfMonth + "-" + exactMonth + "-" + year;
    const startDateReverse = moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const endDateReverse = moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    dispatch({
        type: "SHOW_CALENDAR_LOADER",
        payload: true,
    });

    return axios
        .get("reservation-calendar?filter[between]=" + startDateReverse + "," + endDateReverse)
        .then((result) => {
            const { status, data } = result;

            if (status === 200) {
                dispatch({
                    type: "GET_RESERVATIONS_CALENDAR",
                    payload: data.data,
                });

                dispatch({
                    type: "SHOW_CALENDAR_LOADER",
                    payload: false,
                });
            }
            return result;
        })
        .catch((error) => {
            dispatch({
                type: "SHOW_CALENDAR_LOADER",
                payload: false,
            });
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const checkForPlaces = (date, dispatch) => {
    const departure_at = moment(date).format("YYYY-MM-DD");
    const arrival_at = moment(date).add("days", 1).format("YYYY-MM-DD");
    return axios
        .get(
            "parking-places?departure_at=" +
                departure_at +
                " 00:00:00" +
                "&arrival_at=" +
                arrival_at +
                " 00:00:00"
        )
        .then((result) => {
            const { status, data } = result;

            if (status === 200) {
                dispatch({
                    type: "SET_FREE_PLACES",
                    payload: data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
