import React from "react";
import { useTranslation } from "react-i18next";
import "../../languages/i18nextConf";

import { useSelector, useDispatch } from "react-redux";

import ClosePopup from "../../images/icons/close-popup-icon.svg";

import st from "./styles.module.scss";

const ConfirmDelete = () => {
    const { t } = useTranslation();

    const state = useSelector((state) => state.confirmDelete);
    const text = useSelector((state) => state.confirmDelete.text);

    const dispatch = useDispatch();
    const confirmDelete = (status) => {
        dispatch({
            type: "SET_DELETE_CONFIRM",
            payload: {
                ...state,
                show: false,
                confirm: status,
            },
        });
    };

    const { show } = state;
    return (
        <>
            <div className={st.confirmDelete + " pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => confirmDelete(false)}
                    />
                    <p className={st.text}>{text}</p>

                    <div className={st.buttons}>
                        <button className={st.btn} onClick={() => confirmDelete(false)}>
                            {t("components.delete_pop.no")}
                        </button>
                        <button className={st.red_btn} onClick={() => confirmDelete(true)}>
                            {t("components.delete_pop.yes")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmDelete;
