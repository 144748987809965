import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import CalendarToolbarLogic from "./CalendarToolbarLogic";

const CalendarToolbar = ({ date, label, onNavigate, onView }) => {
    const { t } = useTranslation();
    const { goToDayView, goToWeekView, goToMonthView } = CalendarToolbarLogic(date, onView);

    return (
        <>
            <div className='calendar-toolbar rbc-toolbar'>
                <div className='row align-items-center'>
                    <div className='col-xl-4 text-center col-md-4'>
                        <ul className='legend'>
                            <li>
                                <span className='marker' style={{ background: "#0e74ca" }}></span>
                                {t("reservation_calendar.come")}
                            </li>
                            <li>
                                <span className='marker' style={{ background: "#097200" }}></span>
                                {t("reservation_calendar.active")}
                            </li>
                            <li>
                                <span className='marker' style={{ background: "#ff882a" }}></span>
                                {t("reservation_calendar.returned")}
                            </li>
                        </ul>
                        <ul className='legend'>
                            <li>
                                <span className='marker' style={{ background: "red" }}></span>
                                {t("reservation_calendar.go")}
                            </li>
                            <li>
                                <span className='marker' style={{ background: "#ffc720" }}></span>
                                {t("reservation_calendar.not_come")}
                            </li>
                            <li>
                                <span className='marker' style={{ background: "#d42aff" }}></span>
                                {t("reservation_calendar.not_done")}
                            </li>
                        </ul>
                    </div>

                    <div className='col-xl-4 text-center col-md-4'>
                        <div className='calendar-nav-wrapper'>
                            <div
                                className='toolbar-navigation-button'
                                type='button'
                                onClick={() => onNavigate("PREV")}
                            >
                                <i className='fas fa-caret-left'></i>
                            </div>
                            <div className='current-month'>{label}</div>

                            {/* <span
                            className="today-label"
                            onClick={() => this.props.onNavigate('TODAY')}
                            >
                            today
                        </span> */}
                            <div
                                className='toolbar-navigation-button'
                                type='button'
                                onClick={() => onNavigate("NEXT")}
                            >
                                <i className='fas fa-caret-right'></i>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-4 text-center col-md-4'>
                        <div className='view-switch-container'>
                            <button className='bg-filter-off' onClick={goToDayView}>
                                <span className='label-filter-off'>
                                    {t("reservation_calendar.day")}
                                </span>
                            </button>
                            <button className='bg-filter-off' onClick={goToWeekView}>
                                <span className='label-filter-off'>
                                    {t("reservation_calendar.week")}
                                </span>
                            </button>
                            <button className='bg-filter-off' onClick={goToMonthView}>
                                <span className='label-filter-off'>
                                    {t("reservation_calendar.month")}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarToolbar;
