import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import ListTable from "../../../../components/ListTable/ListTable";

import ListLogic from "./ListLogic";
import Edit from "../Single/Edit";
import Preview from "../Single/Preview";

import st from "./styles.module.scss";

const List = () => {
    const { t } = useTranslation();
    const { list, openParkingPreview, openParkingEdit } = ListLogic();
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    return (
        <>
            {userPermitions["update_parking"] && <Edit />}
            {userPermitions["view_parking"] && <Preview />}
            <div className='page-header top-section-list'>
                <h2 className='title'> {t("parkings.title")} </h2>
                {userPermitions["create_parking"] && (
                    <button
                        onClick={() => openParkingEdit(null)}
                        type='button'
                        className='btn btn-submit'
                    >
                        {t("parkings.add_new_button")}
                    </button>
                )}
            </div>
            <div className={st.list_table + " wrapper"}>
                <ListTable>
                    {list.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.city}</td>
                                <td>{item.street}</td>
                                <td>{item.number}</td>
                                <td>{item.phone}</td>
                                <td>{item.lots}</td>
                                <td>
                                    <div className={st.actions_icons}>
                                        {userPermitions["view_parking"] && (
                                            <i
                                                onClick={() => openParkingPreview(item.id)}
                                                className='fas fa-pen'
                                            ></i>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    {list.length === 0 && (
                        <tr>
                            <td colSpan={8} className='text-center'>
                                {t("parkings.table.noResults")}
                            </td>
                        </tr>
                    )}
                </ListTable>
            </div>
        </>
    );
};

export default List;
