import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import Logic from "./Logic";

import st from "./styles.module.scss";
import pricing_list from "../../../../images/home_icons/pricing_list.svg";

const pricing = [
    { days: 1, val: 70 },
    { days: 2, val: 84 },
    { days: 3, val: 98 },
    { days: 4, val: 112 },
    { days: 5, val: 126 },
    { days: 6, val: 140 },
    { days: 7, val: 154 },
    { days: 8, val: 168 },
    { days: 9, val: 182 },
    { days: 10, val: 196 },
    { days: 11, val: 210 },
    { days: 12, val: 215 },
    { days: 13, val: 220 },
    { days: 14, val: 230 },
    { days: 15, val: 240 },
    { days: 16, val: 250 },
    { days: 17, val: 260 },
    { days: 18, val: 270 },
    { days: 19, val: 280 },
    { days: 20, val: 290 },
];
const Index = () => {
    const { t } = useTranslation();

    const { showPriceCalculator } = Logic();

    return (
        <>
            <div className={st.pricing_section}>
                <div className={st.text_wrapper}>
                    <h4 className={st.title}>{t("pricing_section.title")}</h4>
                    <p className={st.text}>
                        <b>{t("pricing_section.text_1_1")}</b> {t("pricing_section.text_1_2")}
                    </p>
                    <p className={st.text}>{t("pricing_section.text_2")}</p>
                    <div className={st.buttons}>
                        <button
                            className='green_btn_h'
                            onClick={() => {
                                showPriceCalculator(true);
                            }}
                        >
                            {t("pricing_section.link_1")}
                        </button>
                        <a href='tel:+41225101440' className='grey_btn_h'>
                            {t("pricing_section.link_2")}
                        </a>
                    </div>
                </div>
                <div className={st.pricing_table}>
                    <h3 className={st.section_title}>
                        {t("pricing_section.section_title")}{" "}
                        <div className={st.circle}>
                            <img src={pricing_list} alt='' />
                        </div>
                    </h3>
                    <div className={st.inner_table}>
                        {pricing.map((item, i) => {
                            return (
                                <p className={st.days} key={item.days}>
                                    <span className={st.name}>
                                        {i + 1}{" "}
                                        {item.days == 1
                                            ? t("pricing_section.day")
                                            : t("pricing_section.days")}
                                    </span>
                                    <span className={st.val}>{item.val} chf</span>
                                </p>
                            );
                        })}
                        <p className={st.rest}>
                            <span className={st.val}>+10CHF</span> {t("pricing_section.per_day")}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
