import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { useHistory } from "react-router";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getSingleReservation,
    cancelReservation,
    uploadCarImage,
    deleteCarImage,
} from "./_actions";
import { getReservationsList } from "../List/_actions";
import { getReservationsCalendar } from "../../Calendar/_actions";
import { getReservationsListClient } from "../../Clients/Single/Reservations/_actions";

import { getAllVallets, updateSingleReservation, setNewStatus, setNewStatusWithPayment } from "./_actions";

import moment from "moment";

const PreviewLogic = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const clientId = useSelector((state) => state.singleClient.client.id);

    const reservation = useSelector((state) => state.singleReservation.reservation);
    const reservationId = useSelector((state) => state.singleReservation.reservationId);
    const allVallets = useSelector((state) => state.singleReservation.allVallets);
    const showPreview = useSelector((state) => state.singleReservation.showPreview);
    const querySting = useSelector((state) => state.listTableReducer.query);
    const role = useSelector((state) => state.dashboardReducer.role);

    const selectedMonth = useSelector((state) => state.reservationCalendar.selectedMonth);

    const confirmDelete = useSelector((state) => state.confirmDelete);

    const [loading, setLoader] = useState(false);

    const initialState = {
        id: "",
        client_id: "",
        vehicle_id: "",
        departure_at: null,
        arrival_at: null,
        departure_at_time: null,
        arrival_at_time: null,
        flight_number: "",
        departure_valet: "",
        arrival_valet: "",
        created_at: "",
        vehicle_photos: [],
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (reservationId) {
            getSingleReservation(reservationId, dispatch);
        }

        getAllVallets(dispatch);
    }, [reservationId]);

    const [valetsList, setValets] = useState([]);
    const [parkingsList, setParkingsList] = useState([]);

    const [valetData, setValetData] = useState({
        departure_valet: {
            name: "",
            id: "",
        },
        arrival_valet: {
            name: "",
            id: "",
        },
    });

    const handleValetChange = (event, value, type) => {
        if (value) {
            const selected = valetsList.filter((item) => item.id == value.id)[0];
            setValetData({
                ...valetData,
                [type]: {
                    id: value.id,
                    name: selected.name,
                },
            });
        } else {
            setValetData({
                ...valetData,
                [type]: {
                    id: "",
                    name: "",
                },
            });
        }
    };
    useEffect(() => {
        if (allVallets.length > 0) {
            setValets(allVallets);
        }
    }, [allVallets]);

    const [note, changeNote] = useState("");
    const [parking_id, changeParkingId] = useState("");
    useEffect(() => {
        const { departure_at, arrival_at, parkings_list } = reservation;
        const { data: parkings } = parkings_list || {};

        if (Object.keys(reservation).length > 0) {
            setParkingsList(parkings);
            const {
                vehicle_photos,
                arrival_valet = {},
                departure_valet = {},
                note,
                parking_id,
                ...rest
            } = reservation;
            const { id: arrival_valet_id } = arrival_valet || {};
            const { id: departure_valet_id } = departure_valet || {};
            const departure_at_time = moment(departure_at, "DD-MM-YYYY HH:mm").format("hh:mm");
            const arrival_at_time = moment(arrival_at, "DD-MM-YYYY HH:mm").format("hh:mm");

            if (valetsList.length > 0) {
                const selected_dep_valet = valetsList.filter(
                    (item) => item.id == departure_valet_id
                )[0];

                const selected_arr_valet = valetsList.filter(
                    (item) => item.id == arrival_valet_id
                )[0];
                setValetData({
                    departure_valet: {
                        id: selected_dep_valet ? selected_dep_valet.id : "",
                        name: selected_dep_valet ? selected_dep_valet.name : "",
                    },
                    arrival_valet: {
                        id: selected_arr_valet ? selected_arr_valet.id : "",
                        name: selected_arr_valet ? selected_arr_valet.name : "",
                    },
                });
            }

            changeParkingId(parking_id);
            changeNote(note ? note : "");
            setState({
                departure_at_time,
                arrival_at_time,
                vehicle_photos: vehicle_photos.data,
                ...rest,
            });
        }
    }, [reservation, valetsList]);

    useEffect(() => {
        const { location, confirm, id } = confirmDelete;
        if (confirm && location === "reservations_preview") {
            cancelReservation(id, dispatch).then((result) => {
                dispatch({
                    type: "SET_DELETE_CONFIRM",
                    payload: {
                        ...confirmDelete,
                        confirm: false,
                    },
                });
                if (history.location.pathname === "/dashboard/calendar") {
                    getReservationsCalendar(selectedMonth, dispatch);
                } else if (history.location.pathname === "/dashboard/reservations") {
                    getReservationsList(querySting, dispatch);
                } else {
                    getReservationsListClient(clientId, querySting, dispatch);
                }
            });
        }
    }, [confirmDelete]);

    const deleteReservation = (id, fullName) => {
        let text =
            t("reservations.delete.question_1") +
            " “" +
            id +
            "” " +
            t("reservations.delete.question_2");
        dispatch({
            type: "SET_DELETE_CONFIRM",
            payload: {
                show: true,
                text: text,
                location: "reservations_preview",
                id: id,
            },
        });
        dispatch({
            type: "SHOW_PREVIEW_RESERVATION_POPUP",
            payload: false,
        });
    };

    const openReservationPreview = (data) => {
        dispatch({
            type: "SHOW_PREVIEW_RESERVATION_POPUP",
            payload: { id: "", status: data },
        });
    };

    const openReservationEdit = (id) => {
        dispatch({
            type: "SHOW_EDIT_RESERVATION_POPUP",
            payload: { id, status: true, client_id: null },
        });
        dispatch({
            type: "SHOW_PREVIEW_RESERVATION_POPUP",
            payload: { id: "", status: false },
        });
    };

    const handleImageUpload = (event) => {
        setLoader(true);
        // let file = event.target.files[0];

        const files = Object.keys(event.target.files).map((item) => event.target.files[item]);

        uploadCarImage(files, reservationId, dispatch).then((result = {}) => {
            setLoader(false);
            if (result.status === 204) {
                getSingleReservation(reservationId, dispatch);
            }
        });
    };

    const handleDeleteImage = (uuid) => {
        setLoader(true);

        deleteCarImage(uuid, dispatch).then((result = {}) => {
            setLoader(false);
            if (result.status === 204) {
                getSingleReservation(reservationId, dispatch);
                getReservationsList(querySting, dispatch);
                getReservationsCalendar(selectedMonth, dispatch);
            }
        });
    };

    const changeStatus = (status) => {
        setLoader(true);
        setNewStatus(reservationId, status, dispatch).then((response = {}) => {
            if (response.status === 204) {
                const { id } = state;

                let moderated = {
                    id,
                    note,
                    parking_id,
                };

                setState({ ...state, moderated });
                updateReservation(moderated);

                if (history.location.pathname === "/dashboard/calendar") {
                    getReservationsCalendar(selectedMonth, dispatch);
                } else if (history.location.pathname === "/dashboard/reservations") {
                    getReservationsList(querySting, dispatch);
                } else {
                    getReservationsListClient(clientId, querySting, dispatch);
                }

                openReservationPreview(false);
            }
        });
    };
    const changeStatusWithPayment = (status, payment_status) => {
        setLoader(true);
        setNewStatusWithPayment(reservationId, {status, payment_status}, dispatch).then((response = {}) => {
            if (response.status === 204) {
                if (history.location.pathname === "/dashboard/calendar") {
                    getReservationsCalendar(selectedMonth, dispatch);
                } else if (history.location.pathname === "/dashboard/reservations") {
                    getReservationsList(querySting, dispatch);
                } else {
                    getReservationsListClient(clientId, querySting, dispatch);
                }

                openReservationPreview(false);
            }
        });
    };
    const assignValet = (type) => {
        const { id } = state;
        const { arrival_valet, departure_valet } = valetData;
        let moderated = {
            id,
            parking_id,
            note,
        };

        if (type === "departure_valet") {
            moderated = { ...moderated, departure_valet: departure_valet.id };
        }
        if (type === "arrival_valet") {
            moderated = { ...moderated, arrival_valet: arrival_valet.id };
        }
        updateReservation(moderated);
    };

    const onSubmit = async (data, { setErrors }) => {
        const { id } = state;
        setLoader(true);
        let moderated = {
            id,
            note,
            parking_id,
        };

        setState({ ...state, moderated });
        updateReservation(moderated, setErrors);
    };

    const updateReservation = (data, setErrors) => {
        updateSingleReservation(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 204) {
                openReservationPreview(false);
                setState(initialState);
            }

            if (history.location.pathname === "/dashboard/calendar") {
                getReservationsCalendar(selectedMonth, dispatch);
            } else if (history.location.pathname === "/dashboard/reservations") {
                getReservationsList(querySting, dispatch);
            } else {
                getReservationsListClient(clientId, querySting, dispatch);
            }
            // if (response.errors) {
            //     let moderatedErrors = { error: "" };
            //     for (const property in response.errors) {
            //         moderatedErrors = {
            //             ...moderatedErrors,
            //             [property]: response.errors[property].toString(),
            //         };
            //     }

            //     setErrors(response.errors);
            // }
        });
    };
    return {
        parkingsList,
        role,
        state,
        loading,
        openReservationPreview,
        deleteReservation,
        onSubmit,
        handleImageUpload,
        handleDeleteImage,
        valetsList,
        valetData,
        handleValetChange,
        changeStatus,
        assignValet,
        parking_id,
        changeParkingId,
        note,
        changeNote,
        changeStatusWithPayment
    };
};

export default PreviewLogic;
