import * as yup from "yup";

const Schema = yup.object().shape({
    name: yup.string().nullable().required("Requis!"),
    city: yup.string().nullable().required("Requis!"),
    street: yup.string().nullable().required("Requis!"),
    number: yup.string().nullable().required("Requis!"),
    phone: yup.string().nullable().required("Requis!"),
    lots: yup.string().nullable().required("Requis!"),
});

export default Schema;
