import React from "react";
import PasswordValidatorLogic from "./PasswordValidatorLogic";
import st from "./styles.module.scss";

const PasswordValidator = ({ state, handleErrors, handleRulesCompleted }) => {
    const { barsCounter } = PasswordValidatorLogic(state, handleErrors, handleRulesCompleted);

    return (
        <>
            <div className={st["progress-holder"]}>
                <div className={st.bar + (barsCounter >= 1 ? " " + st.show : "")}>
                    <span></span>
                </div>
                <div className={st.bar + (barsCounter >= 2 ? " " + st.show : "")}>
                    <span></span>
                </div>
                <div className={st.bar + (barsCounter >= 3 ? " " + st.show : "")}>
                    <span></span>
                </div>
            </div>
            <ul className={st.requirements}>
                <li>Upper and lowercase letters</li>
                <li>More than 8 characters</li>
                <li>Contains a number or symbol</li>
            </ul>
        </>
    );
};

export default PasswordValidator;
