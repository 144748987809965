import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { useDispatch, useSelector } from "react-redux";
import ClosePopup from "../../../images/icons/close-popup-icon.svg";

import ErrorIcon from "../../../images/home/success_icon_sad.png";

import st from "./styles.module.scss";

const HolidayError = () => {
    const { t } = useTranslation();
    const show = useSelector((state) => state.clientBooking.showHolidayError);

    const dispatch = useDispatch();

    const togglePopUp = (name, value) => {
        dispatch({
            type: "SET_BOOKING_SCREEN",
            payload: { name, value },
        });
    };

    return (
        <div
            className={
                st.small_popup + " " + st.info_popup + " pop client_forms " + (show ? "show" : "")
            }
        >
            <div className={st.inner + " inner"}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => togglePopUp("showHolidayError", false)}
                />
                <div className={st.form_wrapper + " text-center"}>
                    <img src={ErrorIcon} alt='' className='mb-3' />
                    <h3 className='mb-2'>{t("client_booking.non_working_days")} </h3>

                    <button
                        type='button'
                        onClick={() => {
                            togglePopUp("showHolidayError", false);
                        }}
                        className='green_btn_h'
                    >
                        {t("client_booking.close")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HolidayError;
