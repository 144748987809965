import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { TextField } from "formik-material-ui";
import { useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";

import loginSchema from "./../../../../_validations/loginSchema";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";
import st from "../styles.module.scss";

import LoginLogic from "./LoginLogic";

const Login = () => {
    const { t } = useTranslation();

    const show = useSelector((state) => state.clientAuth.showLogin);

    const { togglePopUp, onSubmit, loading, passType, togglePassType, state } = LoginLogic();
    return (
        <div className={st.info_popup + " pop  " + (show ? "show" : "")}>
            <div className={st.inner + " inner"}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => togglePopUp("showLogin", false)}
                />
                <div className={st.form_wrapper}>
                    <h3>{t("client_auth.login")}</h3>
                    <Formik
                        enableReinitialize
                        initialValues={state}
                        validationSchema={loginSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isValid, dirty, errors }) => {
                            Object.keys(errors).map((item) => {
                                errors[item] = t(errors[item]);
                                return errors[item];
                            });
                            return (
                                <Form className='client_forms'>
                                    <Field
                                        name='email'
                                        type='text'
                                        className='input_label'
                                        label={t("client_auth.email")}
                                        component={TextField}
                                    ></Field>
                                    <div className='password_input_label'>
                                        <Field
                                            name='password'
                                            type={passType ? "text" : "password"}
                                            className='input_label'
                                            label={t("client_auth.password")}
                                            component={TextField}
                                        ></Field>
                                        <i
                                            className='password_icon fas fa-eye'
                                            onClick={togglePassType}
                                        ></i>
                                    </div>
                                    <div className={st.btn_wrapper}>
                                        <button
                                            // disabled={loading || !(isValid && dirty)}
                                            disabled={loading}
                                            type='submit'
                                            className='green_btn_h'
                                        >
                                            {t("client_auth.login")}
                                        </button>
                                    </div>

                                    <p className={st.bottom_link + " mt-4"}>
                                        {t("client_auth.forgot")}{" "}
                                        <span
                                            onClick={() => {
                                                togglePopUp("showForgotPassword", true);
                                                togglePopUp("showLogin", false);
                                            }}
                                            className='link'
                                        >
                                            {t("client_auth.reset")}
                                        </span>
                                    </p>

                                    <p className={st.bottom_link}>
                                        {t("client_auth.no_account")}{" "}
                                        <Link
                                            to='reservation'
                                            onClick={() => togglePopUp("showLogin", false)}
                                        >
                                            {t("client_auth.book_now")}
                                        </Link>
                                    </p>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Login;
