import axios from "../../../../../configAxios.js";
import { setMessage } from "../../../../../components/AlertMessage/_actions.js";

export const updateSingleClient = (data, dispatch) => {
    const { id } = data;
    return axios
        .put("clients/" + id, data)
        .then((result) => {
            const { status } = result;
            if (status === 201) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const getCountriesList = (dispatch) => {
    return axios
        .get("countries")
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                const data = result.data.data;
                const Switzerland = data.find((item) => item.name === "Switzerland");
                const France = data.find((item) => item.name === "France");
                const Italy = data.find((item) => item.name === "Italy");
                const Germany = data.find((item) => item.name === "Germany");
                const Uk = data.find((item) => item.name === "United Kingdom");

                const RestOfTheWorld = data.filter((item) => {
                    return (
                        item.name !== "Switzerland" &&
                        item.name !== "France" &&
                        item.name !== "Italy" &&
                        item.name !== "Germany" &&
                        item.name !== "United Kingdom"
                    );
                });

                dispatch({
                    type: "SET_COUNTRY_LIST",
                    payload: [Switzerland, France, Italy, Germany, Uk, ...RestOfTheWorld],
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
