import * as yup from "yup";

const Schema = yup.object().shape({
    first_name: yup.string().required("Requis!"),
    last_name: yup.string().required("Requis!"),
    email: yup.string().email("Еmail non valide !").required("Requis!"),
    role_id: yup.string().required("Requis!"),
});

export default Schema;
