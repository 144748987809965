// clientBooking

const initialState = {
  complete_first_step: false,
  complete_second_step: false,
  enable_second_step: false,
  enable_third_step: false,
  showHolidayError: false,
  aditional_payment: false,
  active_step: 1,
  reservation: { latest_payment: {} },
  reservation_id: 0,
  resetDates: false,
  authErrors: {},
  countriesList: [],
  disableSumbit: true,
  dates: {
    arrival_at: "",
    arrival_at_time: "",
    departure_at: "",
    departure_at_time: "",
  },
  promocode: "",
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_BOOKING_SCREEN":
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    case "RESET_BOOKING_DATA":
      return {
        complete_first_step: false,
        complete_second_step: false,
        enable_second_step: false,
        enable_third_step: false,
        active_step: 1,
        reservation: { latest_payment: {} },
        aditional_payment: false,
        reservation_id: 0,
        resetDates: true,
      };
    case "SET_RESET_DATES":
      return {
        ...state,
        resetDates: payload,
      };
    case "SET_AUTH_ERROR":
      return {
        ...state,
        authErrors: payload,
      };
    case "SET_BOOKING_RESERVATION":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          ...payload,
        },
      };
    case "SET_COUNTRY_LIST":
      return {
        ...state,
        countriesList: payload,
      };
    case "SET_DISABLE_SUBMIT":
      return {
        ...state,
        disableSumbit: payload,
      };
    case "SET_DATES":
      return {
        ...state,
        dates: payload,
      };
    case "CLEAR_DATES":
      return {
        ...state,
        dates: {
          arrival_at: "",
          arrival_at_time: "",
          departure_at: "",
          departure_at_time: "",
        },
      };
    case "SET_PROMOCODE":
      return {
        ...state,
        promocode: payload,
      };
    case "CLEAR_PROMOCODE":
      return {
        ...state,
        promocode: "",
      };
    default:
      return state;
  }
};

export default reducer;
