import axios from "../../../configAxios.js";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

export const createAccount = (data, dispatch) => {
    return axios
        .put("employees/set", data)
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "LOGIN_PROFILE_SETUP",
                    payload: result.data.data,
                });
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
