import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const ListTableLogic = () => {
    const dispatch = useDispatch();
    const tableMeta = useSelector((state) => state.listTableReducer.meta);
    const columnHeaders = useSelector((state) => state.listTableReducer.columnHeaders);
    const tableFilters = useSelector((state) => state.listTableReducer.tableFilters);

    const [currentPage, setCurrentPage] = useState(1);

    const [lastPage, setLastPage] = useState("");
    const [componentMounted, setComponentMounted] = useState(false);
    useEffect(() => {
        setComponentMounted(true);
        return () => {
            dispatch({
                type: "SET_TABLE_FILTERS_SORT",
                payload: { column: [], tableFilters: {}, headerResultLabel: false },
            });
            dispatch({
                type: "SET_LIST_TABLE_QUERY",
                payload: "",
            });
        };
    }, []);

    useEffect(() => {
        if (lastPage && currentPage) {
            if (currentPage > lastPage) {
                setCurrentPage(lastPage);
            }
        }
    }, [lastPage, currentPage]);

    useEffect(() => {
        if (Object.keys(tableMeta).length !== 0) {
            const { last_page } = tableMeta;
            // setPerPage(per_page);
            setLastPage(last_page);
        }
    }, [tableMeta]);

    const changeCurrentPage = (event) => {
        const { value } = event.target;
        if (!isNaN(value) && value > 0 && value <= lastPage) {
            setCurrentPage(value);
        }
    };

    const nextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);
        }
    };
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const [perPage, setPerPage] = useState(10);
    const [sortTable, setSortTable] = useState([]);

    useEffect(() => {
        if (columnHeaders.length > 0 && componentMounted) {
            setSortTable(columnHeaders);
        } else {
            setSortTable([]);
        }
    }, [columnHeaders, componentMounted]);

    const handleSort = (item) => {
        if (item.sort) {
            const { name } = item;
            const data = sortTable.map((item) => {
                if (item.name === name) {
                    return {
                        ...item,
                        status: item.status === "desc" ? "asc" : "desc",
                    };
                } else {
                    return {
                        ...item,
                        status: "none",
                    };
                }
            });

            setSortTable(data);
        }
    };

    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        if (Object.keys(tableFilters).length !== 0) {
            const data = Object.keys(tableFilters).map((item) => {
                return { ...tableFilters[item], value: "" };
            });
            setFilterList(data);
        } else {
            setFilterList([]);
        }
    }, [tableFilters]);

    const handleChangeFilters = (e, i, obj) => {
        const { value } = e.target;
        const data = Object.keys(filterList).map((el) => {
            const item = filterList[el];
            if (item.label === obj.label) {
                return { ...item, value: value };
            } else {
                return { ...item };
            }
        });
        setFilterList(data);
    };

    const [debounseSearch, setDebounseSearch] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSearchTerm(debounseSearch);
        }, 1500);
        return () => clearTimeout(delayDebounceFn);
    }, [debounseSearch]);

    useEffect(() => {
        let url = "page[number]=" + currentPage + "&page[size]=" + perPage;
        console.log("url", url)
        if (sortTable.length > 0) {
            const isSortParam = sortTable
                .filter((item) => item.sort)
                .filter((item) => item.status !== "none");

            if (isSortParam.length !== 0) {
                if (isSortParam[0].status === "desc") {
                    url = url + "&sort=" + isSortParam[0].name;
                } else if (isSortParam[0].status === "asc") {
                    url = url + "&sort=-" + isSortParam[0].name;
                }
            }

            if (filterList.length !== 0) {
                const isfilterParam = filterList.filter((item) => item.value);
                if (isfilterParam.length !== 0) {
                    isfilterParam.map((item) => {
                        url =
                            url +
                            "&filter[" +
                            item.filterName +
                            "]=" +
                            item.data[item.value - 1].name;
                    });
                }
            }

            if (searchTerm.length > 3) {
                url = url + "&filter[search]=" + searchTerm;
            }

            dispatch({
                type: "SET_LIST_TABLE_QUERY",
                payload: url,
            });
        }
    }, [currentPage, perPage, sortTable, filterList, searchTerm]);

    return {
        currentPage,
        perPage,
        lastPage,
        changeCurrentPage,
        handleChangeFilters,
        setPerPage,
        prevPage,
        nextPage,
        handleSort,
        sortTable,
        filterList,
        setDebounseSearch,
    };
};

export default ListTableLogic;
