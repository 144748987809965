import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { getSingleParking, updateParking, createParking } from "./_actions";
import { getParkingsList } from "../List/_actions";

const EditLogic = () => {
    const dispatch = useDispatch();
    const parking = useSelector((state) => state.singleParking.parking);
    const parkingId = useSelector((state) => state.singleParking.parkingId);
    const showEdit = useSelector((state) => state.singleParking.showEdit);

    const [loading, setLoader] = useState(false);

    const intialState = {
        id: "",
        name: "",
        address: "",
        city: "",
        street: "",
        number: "",
        phone: "",
        lots: "",
    };
    const [state, setState] = useState(intialState);

    const clearForm = () => {
        setState({ ...state, id: null });
        setTimeout(() => {
            setState(intialState);
        }, 100);
    };

    useEffect(() => {
        if (parkingId && showEdit) {
            getSingleParking(parkingId, dispatch);
        } else {
            clearForm();
        }
    }, [parkingId, showEdit]);

    useEffect(() => {
        if (Object.keys(parking).length !== 0) {
            setState(parking);
        }
    }, [parking]);

    const openParkingEdit = () => {
        dispatch({
            type: "SHOW_EDIT_PARKING_POPUP",
            payload: { id: "", status: false },
        });
    };

    const querySting = useSelector((state) => state.listTableReducer.query);
    const onSubmit = async (data, { setErrors, resetForm }) => {
        if (parkingId) {
            updateParking(data, dispatch).then((response = {}) => {
                setLoader(false);
                if (response.status === 204) {
                    getParkingsList(querySting, dispatch);
                    dispatch({
                        type: "SHOW_EDIT_PARKING_POPUP",
                        payload: { id: "", status: false },
                    });
                    resetForm({ values: "" });
                    clearForm();
                }

                if (response.errors) {
                    let moderatedErrors = { error: "" };
                    for (const property in response.errors) {
                        moderatedErrors = {
                            ...moderatedErrors,
                            [property]: response.errors[property].toString(),
                        };
                    }

                    setErrors(response.errors);
                }
            });
        } else {
            createParking({ ...data, active: 1 }, dispatch).then((response = {}) => {
                setLoader(false);
                if (response.status === 201) {
                    getParkingsList(querySting, dispatch);
                    dispatch({
                        type: "SHOW_EDIT_PARKING_POPUP",
                        payload: { id: "", status: false },
                    });
                    resetForm({ values: "" });
                    clearForm();
                }

                if (response.errors) {
                    let moderatedErrors = { error: "" };
                    for (const property in response.errors) {
                        moderatedErrors = {
                            ...moderatedErrors,
                            [property]: response.errors[property].toString(),
                        };
                    }

                    setErrors(response.errors);
                }
            });
        }
    };

    return {
        openParkingEdit,
        state,
        onSubmit,
        loading,
        parkingId,
        clearForm,
    };
};

export default EditLogic;
