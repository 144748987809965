import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editReservation, calcPrice } from "../_actions";

import moment from "moment";
import bookingDispatcher from "../../ClientBooking/_dispatcher";

const EditReservationLogic = () => {
    const dispatch = useDispatch();
    const { setBookingScreen, setBookingData } = bookingDispatcher();

    const [loading, setLoader] = useState(false);

    const show = useSelector((state) => state.clientProfile.showEditReservation);
    // const show = true;
    const selectedReservation = useSelector((state) => state.clientProfile.selectedReservation);
    const flight_number = useSelector(
        (state) => state.clientProfile.selectedReservation.flight_number
    );
    const reservationId = useSelector((state) => state.clientProfile.selectedReservation.id);
    const vehicles = useSelector((state) => state.clientProfile.vehicles);

    const showRangeDatePicker = useSelector((state) => state.rangeDatePicker.show);
    const showEditReservation = useSelector((state) => state.clientProfile.showEditReservation);

    const expectVal = useSelector((state) => state.rangeDatePicker.expectVal);

    const arrival_at_time = useSelector((state) => state.timePicker.arrival_at_time);
    const departure_at_time = useSelector((state) => state.timePicker.departure_at_time);
    const expectVal_time = useSelector((state) => state.timePicker.expectVal);

    const initialState = {
        arrival_at: null,
        arrival_at_time: "",
        departure_at: null,
        departure_at_time: "",
        vehicle_id: "",
        flight_number: "",
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (Object.keys(selectedReservation).length !== 0) {
            const { arrival_at, departure_at, vehicle_id, flight_number } = selectedReservation;

            setState({
                arrival_at: moment(arrival_at, "DD-MM-YYYY HH:mm"),
                departure_at: moment(departure_at, "DD-MM-YYYY HH:mm"),
                arrival_at_time: moment(arrival_at, "DD-MM-YYYY HH:mm").format("HH:mm"),
                departure_at_time: moment(departure_at, "DD-MM-YYYY HH:mm").format("HH:mm"),
                vehicle_id,
                flight_number,
            });
            setTimePicker(
                "arrival_at_time",
                moment(arrival_at, "DD-MM-YYYY HH:mm").format("HH:mm")
            );
            setTimePicker(
                "departure_at_time",
                moment(departure_at, "DD-MM-YYYY HH:mm").format("HH:mm")
            );

            setTimePicker("expectVal", true);
        }
    }, [selectedReservation]);

    useEffect(() => {
        if (arrival_at_time && departure_at_time) {
            setState({
                ...state,
                arrival_at_time,
                departure_at_time,
            });
        }
    }, [arrival_at_time, departure_at_time]);

    useEffect(() => {
        if (showRangeDatePicker === false && expectVal) {
            togglePopUp("showEditReservation", true);
        }
    }, [showRangeDatePicker]);

    const toggleTimePicker = (type) => {
        setTimePicker("chosenTime", state[type]);
        setTimePicker("type", type);
        setTimePicker("show", true);
    };

    const setTimePicker = (name, value) => {
        dispatch({
            type: "SET_TIME_PICKER",
            payload: { name, value },
        });
    };

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };

    const setSelectedReservation = (value) => {
        dispatch({
            type: "SET_SELECTED_RESERVATION",
            payload: value,
        });
    };

    const onChange = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const [price, setPrice] = useState({});
    const formatDates = () => {
        const { arrival_at, arrival_at_time, departure_at, departure_at_time } = state;

        const moderated = {
            arrival_at: arrival_at.format("YYYY-MM-DD") + " " + arrival_at_time,
            departure_at: departure_at.format("YYYY-MM-DD") + " " + departure_at_time,
        };
        return moderated;
    };

    const setCalcPrice = () => {
        calcPrice(formatDates(), dispatch).then((response = {}) => {
            if (response.status === 200) {
                const { order_total } = selectedReservation;
                const { day, price, real_days } = response.data.data;

                if (Number(order_total) === price) {
                    setPrice({ day, price, real_days });
                } else {
                    setPrice({
                        day,
                        price: order_total ? price - Number(order_total) : price,
                        real_days,
                    });
                }
            }
        });
    };
    useEffect(() => {
        const { arrival_at, departure_at } = state;

        if (
            arrival_at !== null &&
            departure_at !== null &&
            arrival_at_time.length > 0 &&
            departure_at_time.length > 0 &&
            showEditReservation
        ) {
            setCalcPrice();
        }
    }, [state, showEditReservation]);

    const Scroll = require("react-scroll");

    const scroller = Scroll.scroller;

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        const { vehicle_id } = data;
        const { arrival_at, departure_at } = formatDates();
        const moderated = {
            vehicle_id,
            flight_number,
            note: "",
            id: reservationId,
            arrival_at,
            departure_at,
        };

        editReservation(moderated, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 200) {
                setTimePicker("expectVal", false);
                togglePopUp("showProfile", true);
                togglePopUp("showEditReservation", false);

                if (response.data.require_payment) {
                    togglePopUp("showProfile", false);
                    setBookingScreen("active_step", 2.9);
                    setBookingScreen("aditional_payment", true);

                    setBookingData({
                        reservation_id: reservationId,
                    });
                    scroller.scrollTo("book_section", {
                        duration: 500,
                        smooth: true,
                    });
                }
                if (response.data.error_msg === "You cannot decrease reservation") {
                    togglePopUp("decreaseReservationError", true);
                }
                const { refunded_amount, refunded_days } = response.data;
                if (refunded_amount && refunded_days) {
                    dispatch({
                        type: "SET_DECREASED_RESERVATION_DATA",
                        payload: {
                            refunded_amount,
                            refunded_days,
                        },
                    });
                    togglePopUp("decreaseReservationSuccess", true);
                }
                setSelectedReservation({});
            }
            if (response.errors) {
                setErrors(response.errors);
            }
        });
    };

    return {
        state,
        price,
        setSelectedReservation,
        togglePopUp,
        onSubmit,
        loading,
        toggleTimePicker,
        vehicles,
        onChange,
        setState,
        show,
    };
};

export default EditReservationLogic;
