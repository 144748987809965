import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useDispatch, useSelector } from "react-redux";

import InviteEmployee from "../InviteEmployee/InviteEmployee";
import ListTable from "../../../../components/ListTable/ListTable";

import { resendInvitation } from "./_actions";

import ListLogic from "./ListLogic";
import Preview from "../Single/Preview";
import Edit from "../Single/Edit";
import st from "./styles.module.scss";

const List = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);

    const { list, deleteEmployee, openInvite, openEmployeePreview } = ListLogic();

    return (
        <>
            {userPermitions["create_employee"] && <InviteEmployee />}
            {userPermitions["view_employee"] && <Preview />}
            {userPermitions["update_employee"] && <Edit />}
            <div className='page-header top-section-list'>
                <h2 className='title'> {t("employees.title")}</h2>

                {userPermitions["create_employee"] && (
                    <button type='button' className='btn-green' onClick={openInvite}>
                        {t("employees.inviteButton")}
                    </button>
                )}
            </div>
            <div className={st.list_table + " wrapper"}>
                <ListTable>
                    {list.map((item) => {
                        const fullName = item.first_name + " " + item.last_name;
                        return (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>{item.adress}</td>
                                <td>{item.role}</td>
                                <td>{item.reservations}</td>
                                <td>
                                    {item.additional_hours}
                                    {/* {userPermitions["update_employee"] && (
                                        <i className='ms-2 fas fa-pen'></i>
                                    )} */}
                                </td>
                                <td>
                                    <div className={st.actions_icons}>
                                        {userPermitions["view_employee"] && (
                                            <i
                                                onClick={() => openEmployeePreview(item.id)}
                                                className='fas fa-pen'
                                            ></i>
                                        )}

                                        {item.status === "pending" && (
                                            <i
                                                onClick={() => resendInvitation(item.id, dispatch)}
                                                className='fas fa-envelope'
                                            ></i>
                                        )}
                                        <i
                                            onClick={() => deleteEmployee(item.id, fullName)}
                                            className='fas fa-trash'
                                        ></i>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    {list.length === 0 && (
                        <tr>
                            <td colSpan={10} className='text-center'>
                                {t("employees.table.noResults")}
                            </td>
                        </tr>
                    )}
                </ListTable>
            </div>
        </>
    );
};

export default List;
