import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateProfile, getProfile } from "../_actions";

const EditProfileLogic = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.clientProfile.profile);
    const countriesList = useSelector((state) => state.clientBooking.countriesList);

    const [loading, setLoader] = useState(false);
    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        country_id: "",
        company: "",
        phone: "",
        city: "",
        street: "",
        street_number: "",
        post_code: "",
    });

    const [country, setCountry] = useState({
        id: "",
        name: "",
    });
    const [phoneCode, setPhoneCode] = useState("00");
    useEffect(() => {
        if (profile) {
            if (Object.keys(profile).length !== 0) {
                setState(profile);
            }
        }
    }, [profile]);

    useEffect(() => {
        if (countriesList.length > 0 && Object.keys(profile).length !== 0) {
            const { country_id } = profile;

            if (country_id) {
                const selected = countriesList.filter((item) => item.id == country_id)[0];
                setCountry({
                    id: profile.country_id,
                    name: selected.name,
                });

                setPhoneCode(selected.phonecode);
            }
        }
    }, [countriesList, profile]);

    const handleCountryChange = (event, value, reason, name) => {
        if (value) {
            const selected = countriesList.filter((item) => item.id == value.id)[0];
            setState({
                ...state,
                country_id: value.id,
            });
            setCountry({
                id: value.id,
                name: selected.name,
            });
            setPhoneCode(selected.phonecode);
        } else {
            setState({
                ...state,
                country_id: "",
            });

            setCountry({
                id: "",
                name: "",
            });
            setPhoneCode("00");
        }
    };
    const toggleScreen = (value) => {
        dispatch({
            type: "CHANGE_CLIENT_SCREEN",
            payload: value,
        });
    };
    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        const { phone, ...rest } = data;
        const moderated = {
            phone: parseInt(phone, 10),
            ...rest,
        };
        updateProfile(moderated, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 204) {
                toggleScreen(1);
                getProfile(dispatch);
            }
            if (response.errors) {
                setErrors(response.errors);
            }
        });
    };
    return { country, phoneCode, toggleScreen, state, loading, onSubmit, handleCountryChange };
};

export default EditProfileLogic;
